import React from 'react';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

type Props = {
    onClose: (value: boolean) => void;
    isVisible: boolean
}
const ScheduleLimitModal = (props: Props) => {
    const { isVisible, onClose} = props;
    const navigate = useNavigate();

    const handleOnClose = () => {
        onClose(true);
    }

    const handleNavigate = () => {
        navigate('/dashboard/calendar');
    }
    
    return (
        <Modal className="schedule-limit-modal" visible={isVisible} footer={null} closable={true} maskClosable={true} keyboard={false}
               centered={true} onCancel={handleOnClose}
        >
            <div className='text-center'>
                <div className='mb-3 schedule-title-modal'>面談予約数の上限です</div>
                <div className='mb-3 schedule-subtitle-modal'>面談予約は同時に2件までお申し込み可能です。</div>
                <div>新規の面談予約をご希望の場合は、ご予約済みの面談が終了されてからお申し込みください。</div>
                <div>予約状況はマイページよりご確認頂けます。</div>
                <div className='text-center schedule-limit-button'>
                    <Button type='primary' onClick={handleNavigate}>
                        マイページ
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ScheduleLimitModal;
