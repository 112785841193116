import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';

type Props = {
    uuid: string,
    isVisible: boolean,
    onClose: () => void,
    onClickConfirm: () => void,
    handleReasonCancelInput: (value: string) => void,
}

const CancelMeetingModal = (props: Props) => {
    const { isVisible, onClose, onClickConfirm, handleReasonCancelInput } = props;
    const [form] = Form.useForm();

    const reasonRule: Rule[] = [
        { required: true, message: 'キャンセル理由をご入力ください。' },
    ];

    const onSubmit = (values: { reason: string }) => {
        handleReasonCancelInput(values.reason);
        onClickConfirm();
    };

    return (
        <Modal className='kisoku-details-meetings-modal' width={800} visible={isVisible} footer={null} onCancel={onClose}>
            <div className='text-center title-modal'>面談をキャンセル</div>
            <div className='subtitle-modal'>キャンセルする理由をご入力ください。（必須）</div>
            <Form form={form} onFinish={onSubmit} layout='vertical'>
                <Form.Item name='reason' label='' rules={reasonRule}>
                    <Input.TextArea rows={6} placeholder='キャンセルする理由をご入力ください。' maxLength={500} />
                </Form.Item>
                <div className='form-buttons'>
                    <Button className='general-button cancel-button me-4' onClick={onClose}>
                        <span className='me-3'><CloseOutlined /></span>
                        閉じる
                    </Button>
                    <Button className='general-button submit-button' htmlType='submit'>
                        確認画面に進む
                    </Button>
                </div>
            </Form>
        </Modal>
    );

};

export default CancelMeetingModal;
