import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import logo from 'assets/images/daidolife_logo_original.png';
import maintenance from 'assets/images/maintenance.png';

const Maintenance = () => {
    return (
        <div className='maintenance-container'>
            <section className='maintenance-container-section'>
            <Row>
                <Col className='text-center'>
                    <img style={{'width': 260}} src={logo} alt='not-found' />
                    <div className='title'>【大同生命　公的支援・就業規則診断オンラインサービス】</div>
                    <div className='subtitle'>ただいまサイトメンテナンス中です。</div>
                </Col>
            </Row>
            <Row className='content'>
                <Col className='pt-2'>
                    <div>
                        平素より「公的支援・就業規則診断オンラインサービス」をご活用いただき、誠にありがとうございます。<br/><br/>
                        只今、サイトメンテナンスに伴い、下記期間サービスを停止しております。<br/>
                        恐れ入りますが、再開まで今しばらくお待ちください。
                    </div>
                    <div className='highlight'>
                    ▪ メンテナンス作業予定日時
                    </div>
                    <div className='highlight-schedule'>
                        2025年3月19日(水) 18:00 ～  3月24日(月)  9:00
                    </div>
                    <div>
                        皆様にはご不便、ご迷惑をおかけいたしますが、何卒ご理解いただきますようお願い申し上げます。
                    </div>
                </Col>
                <Col>
                 <img style={{'width': 200, 'height':300}} src={maintenance} alt='not-found' />
                </Col>
            </Row>
            </section>
        </div>
    );
};

export default Maintenance;
