import React, { useEffect } from 'react';
import { Button, Calendar, Col, ConfigProvider, Row, Tag } from 'antd';
import locale from 'antd/lib/locale/ja_JP';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getUserScheduledMeetings, selectMeeting } from 'app/slice/meetingSlice';
import { Link } from 'react-router-dom';
import koutekiIcon from 'assets/images/kouteki_meeting_calendar_icon.png';
import kisokuIcon from 'assets/images/kisoku_meeting_calendar_icon.png';
import blueSquareIcon from 'assets/images/blue_square_icon.png';
import greenSquareIcon from 'assets/images/green_square_icon.png';
import { FundType } from 'enums';

type CalendarHeaderConfig = {
    value: Moment;
    type: CalendarMode;
    onChange: (date: Moment) => void;
    onTypeChange: (type: CalendarMode) => void;
};

type ListData = {
    content: string;
    uuid: string;
    fundType: number;
};

const MeetingCalendar = () => {
    const { schedules, meetingsFromPublicSupportCount, meetingsFromWorkRegulationCount, scheduledMeetingCount } =
        useAppSelector(selectMeeting);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserScheduledMeetings());
    }, [dispatch]);

    const getListData = (value: Moment) => {
        let listData: ListData[] = [];
        schedules.forEach((sched) => {
            const scheduledDate = moment(sched.schedule).format('YYYY/MM/DD');
            const calendarDate = moment(value).format('YYYY/MM/DD');
            const schedule = new Date(sched.schedule);
            const hours = schedule.getHours().toString().padStart(2, '0');
            const minutes = schedule.getMinutes().toString().padStart(2, '0');
            const formattedSchedule = `${hours}:${minutes}~${hours}:${(parseInt(minutes) + 30).toString().padStart(2, '0')}`;
            if (calendarDate === scheduledDate) {
                listData.push({
                    content: formattedSchedule + ' ' + sched.fund_title,
                    uuid: sched.application_uuid,
                    fundType: sched.fund_type,
                });
            }
        });
        return listData;
    };
    // Disable weekends and holidays
    const disabledDate = (current: Moment) => {
        return current && (current.day() === 0 || current.day() === 6); // Disable Sunday (0) and Saturday (6)
    };
    const CalendarHeader = (config: CalendarHeaderConfig) => {
        const onClickPrev = () => {
            let newValue = config.value.clone().add(-1, 'month');
            config.onChange(newValue);
        };

        const onClickNext = () => {
            let newValue = config.value.clone().add(1, 'month');
            config.onChange(newValue);
        };

        return (
            <Row justify='center' align='middle'>
                <Col md={6} xs={24} className='subtitle'>
                    予約済面談：{scheduledMeetingCount}件
                </Col>
                <Col md={12} xs={24} className='month-picker'>
                    <Button onClick={onClickPrev}>
                        <LeftOutlined />
                    </Button>
                    <span className='month-picker-text'>{config.value.format('YYYY年M月')}</span>
                    <Button onClick={onClickNext}>
                        <RightOutlined />
                    </Button>
                </Col>
                <Col md={6} xs={24} className='calendar-icon'>
                    <img src={blueSquareIcon} alt='logo' />
                    <span>公的支援</span>
                    <img src={greenSquareIcon} alt='logo' />
                    <span>就業規則</span>
                </Col>
            </Row>
        );
    };

    const dateCellRender = (value: Moment) => {
        const listData: ListData[] = getListData(value);
        return (
            <ul className='events w-100'>
                {listData.map((item, index) => {
                    const color = [FundType.SUBSIDY, FundType.GRANT].includes(item.fundType) ? 'blue' : 'green';
                    //Redirect to meeting details page
                    const currentType =
                        item.fundType === 4 || item.fundType === 3
                            ? '/kouteki/consultation/' + item.uuid + '/details'
                            : '/working-regulation/meetings/' + item.uuid;
                    return (
                        <li key={index}>
                            <Tag color={color} className='wrap-tag'>
                                <Link to={'/dashboard' + currentType}>{item.content}</Link>
                            </Tag>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <>
            {schedules && (
                <div className='content dashboard-calendar'>
                    <div className='title'>ご利用状況</div>
                    <div className='meeting-count'>
                        <Link to='/dashboard/kouteki/consultation-list'>
                            <Row gutter={16} align='middle'>
                                <Col className='gutter-row meeting-count-title' md={12} xs={24}>
                                    <img src={koutekiIcon} height='32px' width='32px' alt='logo' />
                                    公的支援オンラインサービス
                                </Col>
                                <Col className='gutter-row' md={8} xs={12}>
                                    <div className='meeting-count-display'>
                                        面談済数：<span>{meetingsFromPublicSupportCount}件</span>
                                    </div>
                                </Col>
                                <Col className='gutter-row meeting-count-arrow' md={4} xs={12}>
                                    <RightOutlined className='arrow-icon right-outline' />
                                </Col>
                            </Row>
                        </Link>
                    </div>
                    <div className='meeting-count'>
                        <Link to='/dashboard/working-regulation/meetings'>
                            <Row gutter={16} align='middle'>
                                <Col className='gutter-row meeting-count-title' md={12} xs={24}>
                                    <img src={kisokuIcon} height='32px' alt='logo' />
                                    就業規則診断オンラインサービス
                                </Col>
                                <Col className='gutter-row' md={8} xs={12}>
                                    <div className='meeting-count-display'>
                                        面談済数：<span>{meetingsFromWorkRegulationCount}件</span>
                                    </div>
                                </Col>
                                <Col className='gutter-row meeting-count-arrow' md={4} xs={12}>
                                    <RightOutlined className='arrow-icon right-outline' />
                                </Col>
                            </Row>
                        </Link>
                    </div>

                    <hr className='divider' />
                    <ConfigProvider locale={locale}>
                        <Calendar
                            className='schedule-calendar'
                            disabledDate={disabledDate}
                            dateCellRender={dateCellRender}
                            headerRender={CalendarHeader}
                        />
                    </ConfigProvider>
                </div>
            )}
        </>
    );
};

export default MeetingCalendar;
