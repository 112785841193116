import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

export const ApplicationType = {
    FUND: 1,
    POLICY: 2,
};

type Props = {
    title: string;
    dateCreated: string;
    uuid: string;
    type: number;
};

const ReadNotification = (props: Props) => {
    const { title, dateCreated, uuid, type } = props;
    const navigate = useNavigate();

    const redirectionLink =
        type === ApplicationType.FUND
            ? `/dashboard/kouteki/consultation/${uuid}/details`
            : `/dashboard/working-regulation/meetings/${uuid}`;

    const onRedirectDetailPage = () => {
        navigate(redirectionLink);
    };

    return (
        <>
            <div className='notification-container notification-container_read'>
                <Row gutter={16} className='notification-row' onClick={onRedirectDetailPage}>
                    <Col className='gutter-row' md={2} xs={24}>
                        <span className='notification-status-title-read'>既読</span>
                    </Col>
                    <Col className='gutter-row' md={3} xs={24}>
                        <span className='notification-date'>{dateCreated}</span>
                    </Col>
                    <Col className='gutter-row' md={3} xs={24}>
                        <div className={type === 1 ? 'notification-title-type-koteki' : 'notification-title-type-kisoku'}>
                            {type === 1 ? '公的資金' : '就業規則'}
                        </div>
                    </Col>
                    <Col className='gutter-row' md={14} xs={24}>
                        <div className='notification-title'>{title}</div>
                    </Col>
                    <Col className='gutter-row' md={2} xs={24}>
                        <RightOutlined className='notification-btn-icon' />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ReadNotification;
