import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { createCompanyPolicyApplication, CreateCompanyPolicyApplicationRequest, Topic, Type } from 'app/slice/policySlice';
import { MeetingRequest } from './RequestMeetingModal';
import AppointmentBookedModal from './AppointmentBookedModal';

type Props = {
    request?: MeetingRequest;
    types: Type[];
    isVisible: boolean;
    scheduleLabel: string;
    onOpenRequestMeetingModal: () => void;
    onClose: () => void;
};

const RequestMeetingConfirmationModal = (props: Props) => {
    const { request, types, isVisible, scheduleLabel, onOpenRequestMeetingModal, onClose } = props;
    const dispatch = useAppDispatch();
    const [isAppointmentBookedVisible, setIsAppointmentBookedVisible] = useState(false);
    const onCloseAppointmentBookedModal = () => setIsAppointmentBookedVisible(false);

    const onRequestSubmit = () => {
        // This is where the meeting request POST to API
        let codes = [] as string[];
        request?.topics?.forEach((topic: Topic) => {
            codes.push(topic.code);
        });

        let data = {
            meeting: {
                schedule: request?.meeting.schedule,
                daido_joins: request?.meeting.daido_joins,
                calendar_type: 3, // Always 3 since it is POLICY
            },
            topics: codes,
            type: request?.type,
            remarks: request?.remarks,
        } as CreateCompanyPolicyApplicationRequest;

        dispatch(createCompanyPolicyApplication(data));
        onClose();
        setIsAppointmentBookedVisible(true);
    };

    const onCloseRequestMeetingConfirmationModal = () => {
        onClose();
    };

    const onBackToRequestMeetingModal = () => {
        onCloseRequestMeetingConfirmationModal();
        onOpenRequestMeetingModal();
    };

    return (
        <>
            <Modal className='meeting-confirmation-modal' visible={isVisible} onCancel={onClose} footer={null}>
                <div className='title'>下記の内容で予約しますか？</div>

                <div className='confirmation-div-top'>
                    <Row className='confirmation-row'>
                        <Col span={8} className='confirmation-label'>
                            面談日時
                        </Col>

                        <Col span={16} className='confirmation-date'>
                            {scheduleLabel}
                        </Col>
                    </Row>
                </div>

                <div className='confirmation-div'>
                    <Row className='confirmation-row'>
                        <Col span={8} className='confirmation-label'>
                            面談を行う規程
                        </Col>

                        <Col span={16} className='confirmation-value'>
                            {types?.find((type) => +type.code === request?.type)?.name}
                        </Col>
                    </Row>
                </div>

                <div className='confirmation-div'>
                    <Row className='confirmation-row'>
                        <Col span={8} className='confirmation-label'>
                            特にご相談したいこと
                        </Col>

                        <Col span={16} className='confirmation-list'>
                            <ul>
                                {request?.topics?.map((topic, key) => (
                                    <li key={key}>{topic.name}</li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </div>

                <div className='confirmation-div'>
                    <Row className='confirmation-row'>
                        <Col span={8} className='confirmation-label'>
                            ご相談内容
                        </Col>

                        <Col span={16} className='confirmation-value'>
                            {request?.remarks}
                        </Col>
                    </Row>
                </div>

                <div className='confirmation-div'>
                    <Row className='confirmation-row'>
                        <Col span={8} className='confirmation-label'>
                            大同生命担当者
                        </Col>

                        <Col span={16} className='confirmation-value'>
                            {request?.meeting.daido_joins ? '同席を希望します' : '同席を希望しません'}
                        </Col>
                    </Row>
                </div>

                <div className='action-buttons'>
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            <Button className='btn-close' onClick={onBackToRequestMeetingModal}>
                                <h3 className='btn-close-text'>
                                    <LeftOutlined className='btn-icon' />
                                    戻る
                                </h3>
                            </Button>
                        </Col>
                        <Col span={12} className='col-submit'>
                            <Button className='btn-submit' htmlType='submit' onClick={onRequestSubmit}>
                                <h3 className='btn-submit-text'>予約する</h3>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <AppointmentBookedModal
                isVisible={isAppointmentBookedVisible}
                scheduleLabel={scheduleLabel}
                onClose={onCloseAppointmentBookedModal}
            />
        </>
    );
};

export default RequestMeetingConfirmationModal;
