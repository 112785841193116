import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useParams } from 'react-router-dom';
import { getApplicationDetails, reset, selectApplicationDetails } from 'app/slice/applicationDetailsSlice';
import { reset as companyReset } from 'app/slice/companySlice';
import KoutekiMeetingDetails from 'components/kouteki/KoutekiMeetingDetails';
import ConsultationResultCancelModal from 'components/consultation/ConsultationResultCancelModal';
import ScheduleConfirmationModal from 'components/consultation/ScheduleConfirmationModal';
import ConsultationConfirmCancelModal from 'components/consultation/ConsultationConfirmCancelModal';
import ConsultationCancelModal from 'components/consultation/ConsultationCancelModal';
import ChangeScheduleModal from 'components/common/ChangeScheduleModal';
import CustomModal from 'components/common/CustomModal';
import errorIcon from 'assets/images/error.png';
import moment from 'moment';

type Params = {
    uuid: string;
    specialist?: string;
};

const ConsultationDetails = () => {
    const { loading, data } = useAppSelector(selectApplicationDetails);
    const dispatch = useAppDispatch();
    const params = useParams<Params>();
    const [isScheduleConfirmationModalVisible, setIsScheduleConfirmationModalVisible] = useState<boolean>(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false);
    const [reasonCancelInput, setReasonCancelInput] = useState('');
    const [isChangeScheduleModalVisible, setIsChangeScheduleModalVisible] = useState(false);
    const [isConfirmCancelModalVisible, setIsConfirmCancelModalVisible] = useState(false);
    const [isResultCancelModalVisible, setIsResultCancelModalVisible] = useState(false);
    const [isApplicationCreationErrorVisible, setIsApplicationCreationErrorVisible] = useState(false);
    const onCloseApplicationCreationErrorModal = () => setIsApplicationCreationErrorVisible(false);

    // Get Application Details
    useEffect(() => {
        // Reset data to make sure previous state is not displayed
        dispatch(reset());

        if (params.uuid) {
            dispatch(getApplicationDetails(params.uuid));
        }
    }, [dispatch, params.uuid]);

    const openChangeScheduleModal = () => {
        const today = moment();
        const formattedCurrentDate = today.format('YYYY.MM.DD');
        const currentScheduleDate = data.schedule;
        const formattedCurrentScheduleDate = currentScheduleDate!.replace('年', '.').replace('月', '.').replace('日', '');

        dispatch(companyReset());

        if (formattedCurrentDate === formattedCurrentScheduleDate) {
            setIsApplicationCreationErrorVisible(true);
        } else {
            setIsChangeScheduleModalVisible(true);
        }
    };

    const onCloseScheduleConfirmationModal = () => {
        setIsScheduleConfirmationModalVisible(false);
    };

    const onCloseChangeScheduleModal = () => setIsChangeScheduleModalVisible(false);

    const handleReasonCancelInput = (value: string) => {
        setReasonCancelInput(value);
    };

    // for cancel modal
    const onCloseCancelModal = () => setIsCancelModalVisible(false);
    const onOpenCancelModal = () => setIsCancelModalVisible(true);

    // for confirm cancel modal
    const handleOpenConfirmCancelModal = () => {
        setIsCancelModalVisible(!isCancelModalVisible);
        setIsConfirmCancelModalVisible(!isConfirmCancelModalVisible);
    };

    const handleReturnConfirmCancelModal = () => {
        setIsConfirmCancelModalVisible(!isConfirmCancelModalVisible);
        setIsCancelModalVisible(!isCancelModalVisible);
    };
    const handleCloseConfirmCancelModal = () => setIsConfirmCancelModalVisible(false);

    // for result cancel modal
    const handleOpenResultCancelModal = () => {
        setIsConfirmCancelModalVisible(!isConfirmCancelModalVisible);
        setIsResultCancelModalVisible(!isResultCancelModalVisible);
    };
    const handleCloseResultCancelModal = () => setIsResultCancelModalVisible(false);

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <>
                    <div className='content consultation-details'>
                        <KoutekiMeetingDetails
                            title={'面談詳細'}
                            data={data}
                            primaryOnClick={openChangeScheduleModal}
                            secondaryOnClick={onOpenCancelModal}
                        />

                        {/* Modal to change Kouteki meeting schedule */}
                        <ChangeScheduleModal
                            isVisible={isChangeScheduleModalVisible}
                            title='面談予約 日程変更'
                            reasonText={
                                '・変更を希望する面談日時を、下のカレンダーから新たに選択してください。\n ・面談予約枠は、本日より7営業日以降の日程から選択可能です。'
                            }
                            uuid={data?.uuid}
                            oldSchedule={data?.scheduleDatetime}
                            fundType={data?.funds?.[0]?.type}
                            onClose={onCloseChangeScheduleModal}
                            onOpenChangeScheduleModal={openChangeScheduleModal}
                        />
                    </div>
                </>
            )}
            <ConsultationCancelModal
                uuid={params.uuid!}
                isVisible={isCancelModalVisible}
                onClose={onCloseCancelModal}
                onClickConfirm={handleOpenConfirmCancelModal}
                handleReasonCancelInput={handleReasonCancelInput}
            />
            <ConsultationConfirmCancelModal
                uuid={params.uuid!}
                isVisible={isConfirmCancelModalVisible}
                onClose={handleCloseConfirmCancelModal}
                onClickReturn={handleReturnConfirmCancelModal}
                onClickConfirm={handleOpenResultCancelModal}
                application={data}
                reasonCancelInput={reasonCancelInput}
            />
            <ConsultationResultCancelModal
                uuid={params.uuid!}
                isVisible={isResultCancelModalVisible}
                onClose={handleCloseResultCancelModal}
                application={data}
            />
            <ScheduleConfirmationModal
                application={data}
                isVisible={isScheduleConfirmationModalVisible}
                onClose={onCloseScheduleConfirmationModal}
            />

            {/*Modal when an applications cannot be changed on the day*/}
            <CustomModal
                isVisible={isApplicationCreationErrorVisible}
                onClose={onCloseApplicationCreationErrorModal}
                title=''
                icon={errorIcon}
                text='当日に予約変更はできません。当日の変更連絡は大同生命の営業担当者までご連絡ください。'
                showCancelButton={false}
                showConfirmButton={true}
                confirmButtonText='閉じる'
                confirmOnClick={onCloseApplicationCreationErrorModal}
            />
        </>
    );
};

export default ConsultationDetails;
