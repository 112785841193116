import React, { useState } from 'react';
import { ConsultationStatuses, MeetingStatuses } from 'enums';
import { Button, message } from 'antd';
import { CopyOutlined, DownOutlined, LeftOutlined, UpOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApplicationDetails } from 'app/slice/applicationDetailsSlice';
import { formatDate } from 'lib/utils';

type Props = {
    title: string;
    data: ApplicationDetails;
    primaryOnClick?: () => void;
    secondaryOnClick?: () => void;
};

type LocationState = {
    searchParams: string;
};

const getStatusClass = (content: string) => {
    let className = '';
    switch (content) {
        case ConsultationStatuses.SCHEDULED:
            className += 'scheduled';
            break;
        case ConsultationStatuses.FINISHED:
            className += 'finished';
            break;
        case ConsultationStatuses.CANCELED:
            className += 'cancelled';
            break;
        case ConsultationStatuses.NOT_IMPLEMENTED:
            className += 'not-implemented';
            break;
        default:
            className += 'default';
            break;
    }

    return className;
};

const KoutekiMeetingDetails = (props: Props) => {
    const { title, data, primaryOnClick, secondaryOnClick } = props;
    const navigate = useNavigate();
    const locationState = useLocation().state as LocationState;
    const [messageApi, contextHolder] = message.useMessage();
    const [showFundRequirements, setShowFundRequirements] = useState(false);
    const meetingCreatedDate = data?.meetingCreatedDate;
    const cancellationDate = data?.cancellationDate;
    const formattedMeetingCreatedDate = formatDate(meetingCreatedDate);
    const formattedCancellationDate = formatDate(cancellationDate);

    const toggleShowFundRequirements = () => {
        setShowFundRequirements(!showFundRequirements);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(data?.joinUrl || '').then(() => messageApi.info('URLをコピーしました'));
    };

    const goBack = () => {
        navigate('/dashboard/kouteki/consultation-list' + (locationState?.searchParams ?? ''));
    };

    return (
        <>
            {contextHolder}
            <div className='meeting-details'>
                <div className={'d-flex row w-100'}>
                    <div className='meeting-details-title'>
                        <strong>{title}</strong>
                    </div>
                    <div className={`card-container ${getStatusClass(data?.label?.content)} meeting-details-card`}>
                        <strong className={`status-${getStatusClass(data?.label?.content)} meeting-details-status`}>
                            {data.label?.content}
                        </strong>
                    </div>
                </div>

                <div className={'d-flex row align-self-center'}>
                    <Button onClick={goBack} shape={'round'} className='round-button meeting-details-back-button' htmlType='button'>
                        <LeftOutlined /> 面談一覧へ戻る
                    </Button>
                </div>
            </div>
            <div className={'d-flex row justify-content-sm-end mt-4'}>
                <span>面談依頼日：{formattedMeetingCreatedDate}</span>
            </div>
            <div className={'d-flex flex-column'}>
                <div className={'d-flex row border-top mt-2 meeting-details-border'}>
                    <div className={'d-flex py-3 mx-3 w-25 meeting-details-label-title'}>公的資金名称</div>
                    <div className={'d-flex row py-3 mx-3 w-75 flex-wrap'}>
                        <div className={'d-flex separation-color meeting-details-divider'}>|</div>
                        {/* can generate multiple fund titles */}
                        <div className={'d-flex flex-column mx-3'}>
                            {data?.funds?.map((fund, key) => (
                                <div key={key} className={'d-flex ms-4 flex-column meeting-details-fund-title'}>
                                    {fund.title}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={'d-flex row border-top mt-2 meeting-details-border'}>
                    <div className={'py-3 mx-3 w-25 meeting-details-label-title'}>面談日時</div>
                    <div className={'d-flex row py-3 mx-3 w-75'}>
                        <div className={'d-flex separation-color'}>|</div>
                        <div className={'d-flex ms-4 meeting-details-date'}>
                            <strong>{data?.scheduleDatetime}</strong>
                        </div>
                    </div>
                </div>
                <div className={'d-flex row border-top mt-2 meeting-details-border'}>
                    <div className={'py-3 mx-3 w-25 meeting-details-label-title'}>ミーティングURL</div>
                    <div className={'d-flex row py-3 mx-3 w-75'}>
                        <div className={'d-flex separation-color'}>|</div>
                        <div className={'d-flex ms-4 w-100 justify-content-between'}>
                            {data?.label?.content === ConsultationStatuses.FINISHED ? (
                                <div className={'d-flex'}>本面談は終了いたしました</div>
                            ) : data?.label?.content === ConsultationStatuses.CANCELED ? (
                                <div className={'d-flex'}>本面談はキャンセルとなりました</div>
                            ) : data?.label?.content === ConsultationStatuses.NOT_IMPLEMENTED ? (
                                <div className={'d-flex'}>ご予約のお時間にZoomへの入室がなかったため、本面談はキャンセルとなりました</div>
                            ) : (
                                data?.joinUrl && (
                                    <>
                                        <div className={'d-flex'}>
                                            <a className='link' href={data?.joinUrl || ''} target='_blank' rel='noreferrer'>
                                                {data?.joinUrl}
                                            </a>
                                        </div>
                                        <div onClick={copyToClipboard} className={'d-flex align-items-center copy-clipboard'}>
                                            <CopyOutlined /> <span className='text-decoration-underline'>URLをコピー</span>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className={'d-flex row border-top mt-2 meeting-details-border'}>
                    <div className={'py-3 mx-3 w-25 meeting-details-label-title'}>入室パスワード</div>
                    <div className={'d-flex row py-3 mx-3 w-75'}>
                        <div className={'d-flex separation-color'}>|</div>
                        <div className={'d-flex ms-4'}>
                            {data?.label?.content === ConsultationStatuses.FINISHED ||
                            data?.label?.content === ConsultationStatuses.CANCELED
                                ? 'ー'
                                : data?.password}
                        </div>
                    </div>
                </div>
                <div className={'d-flex row border-top mt-2 meeting-details-border'}>
                    <div className={'py-3 mx-3 w-25 meeting-details-label-title'}>面談のご案内</div>
                    <div className={'d-flex row py-3 mx-3 w-75'}>
                        <div className={'d-flex separation-color'}>|</div>
                        <div className={'d-flex flex-column'}>
                            <div className={'d-flex ms-4'}>
                                <div>※</div>
                                <div>本面談は「社会保険労務士」または「中小企業診断士」がご対応いたします。</div>
                            </div>
                            <div className={'d-flex ms-4'}>
                                <div>※</div>
                                <div>面談ルームへの入室は予約時刻の５分前から可能です。</div>
                            </div>
                            <div className={'d-flex ms-4'}>
                                <div>※</div>
                                <div>
                                    面談時間は予約時刻から３０分間です。３０分を過ぎた場合は予告なく面談終了となる可能性がござい
                                    ます。予めご了承ください。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'d-flex row border-top border-bottom mt-2 meeting-details-border'}>
                    <div className={'py-3 mx-3 w-25 meeting-details-label-title'}>大同生命担当者</div>
                    <div className={'d-flex row py-3 mx-3 w-75'}>
                        <div className={'d-flex separation-color'}>|</div>
                        <div className={'d-flex ms-4'}>{data?.daidoJoins ? '同席を希望する' : '同席を希望しない'}</div>
                    </div>
                </div>
                <div className='meeting-details-background mt-4 mb-4'>
                    <div className={'d-flex row py-3 meeting-details-border'}>
                        <div className={'mx-3 w-25 meeting-details-discussion-title'}>回答項目</div>
                        <div className={'d-flex row mx-3 w-50'}>
                            <div className={'d-flex'}>上記公的資金の診断の際に選択・チェックされた項目です。</div>
                        </div>
                        <div className={'d-flex row align-self-center w-25 justify-content-sm-end mx-4'}>
                            <Button
                                shape={'round'}
                                onClick={toggleShowFundRequirements}
                                className='round-button meeting-details-accordion-button'
                                htmlType='button'
                            >
                                詳細を表示{showFundRequirements ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                        </div>
                    </div>
                    {showFundRequirements && (
                        <div className={'d-flex flex-column mx-3 mb-4 meeting-details-requirements'}>
                            <div className={'d-flex flex-column'}>
                                {/* Applicable to Josekin with basic criteria */}
                                {data?.funds?.[0].type === 4 && (
                                    <div>
                                        {data?.funds?.[0]?.fundRequirements
                                            .filter((fund) => fund.title === '基本確認項目')
                                            .map((fr) => (
                                                <div className={'d-flex flex-column'}>
                                                    <div className={'requirement-title my-2'}>{fr?.title}</div>
                                                    <ul>
                                                        {fr?.requirements?.map((r) => (
                                                            <li>{r?.content}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                    </div>
                                )}

                                {/* Applicable to Hojokin criteria and Josekin additonal criteria. */}
                                {data?.funds?.map((fund, fKey) => (
                                    <div key={fKey} className={'d-flex flex-column'}>
                                        {fund.fundRequirements
                                            .filter((fund) => fund.title !== '基本確認項目')
                                            .map((fr, frKey) => (
                                                <div className={'d-flex flex-column'}>
                                                    <div key={frKey} className={'requirement-title my-2'}>
                                                        {fr?.title}
                                                    </div>
                                                    <ul>
                                                        {fr?.requirements?.map((r) => (
                                                            <li>{r?.content}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* remarks detail page */}
                <div className={'d-flex flex-column border mb-4 meeting-details-border'}>
                    <div className={'pt-3 mx-3 w-25 fw-bolder meeting-details-remarks-title'}>ご相談内容</div>
                    <div className={'d-flex row py-3 mx-3'}>{data?.funds?.[0]?.applicationRemarks || 'ー'}</div>
                </div>

                {/* cancel detail page */}
                {data?.label?.content === MeetingStatuses.CANCELED && (
                    <div className={'d-flex flex-column border mt-2 mb-2 meeting-details-border'}>
                        <div className={'d-flex row'}>
                            <div className={'d-flex pt-3 row mx-3 w-75 fw-bolder meeting-details-remarks-title'}>キャンセル理由</div>
                            <div className={'d-flex row align-self-center w-25 justify-content-sm-end mx-4'}>
                                <span>キャンセルした日：{formattedCancellationDate}</span>
                            </div>
                        </div>
                        <div className={'d-flex row py-3 mx-3'}>{data?.cancellationReason}</div>
                    </div>
                )}
            </div>

            {/* Show change button and can change schedule only when status is scheduled */}
            {data?.label?.content === MeetingStatuses.SCHEDULED && (
                <div className={'d-flex row w-100 my-3'}>
                    <Button shape={'round'} onClick={primaryOnClick} className='round-button primary-btn' htmlType='button'>
                        予約日程を変更する
                    </Button>
                    <Button shape={'round'} onClick={secondaryOnClick} className='round-button secondary-button' htmlType='button'>
                        予約をキャンセルする
                    </Button>
                </div>
            )}
        </>
    );
};

export default KoutekiMeetingDetails;
