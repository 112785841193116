import React, { useEffect } from 'react';
import { useStyle } from 'lib/utils';
import Text from 'antd/lib/typography/Text';
import { Button, Card, Col, Image, Row, Skeleton } from 'antd';
import firstimage from 'assets/images/work_regulation_chart_flow.png';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getTopicList, selectTopic } from 'app/slice/topicSlice';
import { range } from 'lodash';
import { NotificationType } from 'enums';
import RegulationsTable from 'components/kisoku/RegulationsTable';
import { selectAuth } from 'app/slice/authSlice';

const WorkRegulationLanding = () => {
    useStyle('work-regulation');

    const dispatch = useAppDispatch();
    const { data: topics, loading, pagination } = useAppSelector(selectTopic);
    const { token } = useAppSelector(selectAuth);
    const location = useLocation();

    useEffect(() => {
        dispatch(getTopicList({
            type: NotificationType.KISOKU,
            perPage: 5,
        }));
    }, [dispatch]);

    
    useEffect(() => {
        // Scroll to the section specified in the hash of the URL
        const sectionId = location.hash.replace('#', '');
        if (sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <>
            <div className='landing-container'>
                <section className='primary-content'>
                    <Text className='title'>就業規則診断</Text>
                    <Text className='subtitle'>
                        現行法令の観点から、貴社の就業規則における留意事項を簡易診断いたします。
                    </Text>
                    <Text className='content'>
                        ※本サービスをご利用になるにはログインが必要です。<br />
                        ※本サービスで簡易診断または相談を利用できるのは、就業規則・賃金規程・育児介護休業規程となります。<br />
                        その他の附属規程等は本サービスの対象外となりますので、あらかじめご了承ください。
                    </Text>
                </section>
                <section className='secondary-content'>
                    <Text className='title'>サービスご利用の流れ</Text>
                    <Row className='text-justify'>
                        <Col md={{ span: 12, offset: 7 }} xs={{ span: 20, offset: 2 }}>
                            <Text className='subtitle'>
                                就業規則診断のお申し込み
                            </Text>
                            <Text className='content'>
                                就業規則のファイルをアップロードすることでお申し込みが完了します。
                            </Text>
                            <Text className='subtitle'>
                                就業規則簡易診断
                            </Text>
                            <Text className='content'>
                                アップロードされた就業規則のファイルを社労士事務所が簡易診断したうえで、診断レポートを提供いたします。
                            </Text>
                            <Text className='subtitle'>
                                専門家への相談サービス申込 ※任意。
                            </Text>
                            <Text className='content'>
                                診断レポートをもとに、ご要望に応じて専門家への相談サービスをお申し込みいただけます。
                            </Text>
                            <Text className='content mt-20px working-regulation-login'>
                                <span className='red-3'>右上のボタン</span>からログイン後、管理画面に進み、「就業規則診断サービス」「診断申込管理」よりお申し込みください。
                            </Text>
                        </Col>
                    </Row>
                    <div className='text-center mt-43px'>
                        <Image src={firstimage} className='illustration-img' preview={false} />
                    </div>
                </section>
                <section className='primary-content'>
                    <Text className='title'>就業規則に関連するトピックス</Text>
                    <div className='topics'>
                        {loading ? (
                            <div className='my-3'>
                                {range(0, 5).map(key => (
                                    <Skeleton.Button key={key} active size='large' block className='mb-2' />
                                ))}
                            </div>
                        ) : topics.map((topic, key) => (
                            <Card size='small' key={key}>
                                <Row>
                                    <Col md={3} xs={24} className='topic-date-col'>
                                        <div className='topic-date-container'>{topic.sendDateList}</div>
                                    </Col>
                                    <Col md={18} xs={24} className='topic-text-col'>
                                        {topic.title}
                                    </Col>
                                    <Col md={3} xs={24} className='topic-button-col'>
                                        <Link to={`/kisoku/topics/${topic.uuid}`}>
                                            <Button className='secondary small'>詳細</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                        {pagination?.total > 5 && (
                            <div className='mt-2 text-center'>
                                <Link to='/kisoku/topics'>もっと見る</Link>
                            </div>
                        )}
                    </div>
                </section>
                {token && (
                    <section id='regulations-content' className='regulations-content'>
                        <div className='note'>
                            <li>本サイトでは、社内規程のひな形を提供しています。規程名をクリックすることでダウンロードいただけます。</li>
                            <li>下表で○印が付いている規程は、本サービスで診断や専門家への相談が可能なものです。</li>
                        </div>
                        <RegulationsTable />
                    </section>
                )}
            </div>
        </>
    );
};

export default WorkRegulationLanding;
