import React from 'react';
import { Col, Row } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { markAsViewed } from 'app/slice/notificationSlice';
import { useNavigate } from 'react-router-dom';
import { ApplicationType } from './ReadNotification';
import { RightOutlined } from '@ant-design/icons';
import { NotificationStatusClasses, NotificationStatusEnum } from 'enums';

type Props = {
    title: string;
    dateCreated: string;
    uuid: string;
    id: number;
    type: number;
};

const UnreadNotification = (props: Props) => {
    const { title, dateCreated, uuid, id, type } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const redirectionLink =
        type === ApplicationType.FUND
            ? `/dashboard/kouteki/consultation/${uuid}/details`
            : `/dashboard/working-regulation/meetings/${uuid}`;

    const onReadNotification = () => {
        dispatch(markAsViewed({ id }));
        navigate(redirectionLink);
    };

    const getClassName = (text: string) => {
        // Convert the text to lowercase for case-insensitive comparison
        const searchText = text.toLowerCase();

        switch (true) {
            case searchText.includes(NotificationStatusEnum.Scheduled):
                return NotificationStatusClasses.Scheduled;
            case searchText.includes(NotificationStatusEnum.Finished):
                return NotificationStatusClasses.Finished;
            case searchText.includes(NotificationStatusEnum.Skipped):
                return NotificationStatusClasses.Skipped;
            case searchText.includes(NotificationStatusEnum.Cancelled):
                return NotificationStatusClasses.Cancelled;
            default:
                return NotificationStatusClasses.Expired;
        }
    };

    const getStatusLabel = (text: string) => {
        // Convert the text to lowercase for case-insensitive comparison
        const searchText = text.toLowerCase();

        switch (true) {
            case searchText.includes(NotificationStatusEnum.Scheduled):
                return NotificationStatusEnum.Scheduled;
            case searchText.includes(NotificationStatusEnum.Finished):
                return NotificationStatusEnum.Finished;
            case searchText.includes(NotificationStatusEnum.Skipped):
                return NotificationStatusEnum.Skipped;
            case searchText.includes(NotificationStatusEnum.Cancelled):
                return NotificationStatusEnum.Cancelled;
            default:
                return NotificationStatusEnum.Expired;
        }
    };

    const className = getClassName(title);
    const statusLabel = getStatusLabel(title);

    // Extract part of the title that contains the statusLabel
    const parts = title.split(statusLabel);

    return (
        <>
            <div className='notification-container notification-container_unread'>
                <Row gutter={16} className='notification-row' onClick={onReadNotification}>
                    <Col className='gutter-row' md={2} xs={24}>
                        <span className='notification-status-title-unread'>未読</span>
                    </Col>
                    <Col className='gutter-row' md={3} xs={24}>
                        <span className='notification-date'>{dateCreated}</span>
                    </Col>
                    <Col className='gutter-row' md={3} xs={24}>
                        <div className={type === 1 ? 'notification-title-type-koteki' : 'notification-title-type-kisoku'}>
                            {type === 1 ? '公的資金' : '就業規則'}
                        </div>
                    </Col>
                    <Col className='gutter-row' md={14} xs={24}>
                        {parts.length > 1 ? (
                            <div className='notification-title-unread'>
                                {parts[0]}
                                <span className={className}>{statusLabel}</span>
                                {parts[1]}
                            </div>
                        ) : (
                            <div className='notification-title-unread'>{title}</div>
                        )}
                    </Col>
                    <Col className='gutter-row' md={2} xs={24}>
                        <RightOutlined className='notification-btn-icon' />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default UnreadNotification;
