import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getScheduledMeetings, addEvaluationMeeting, getScheduledMeetingEvaluations } from 'api/meeting';
import _ from 'lodash';


/**
 * GET user's scheduled meetings
 **/
export const getUserScheduledMeetings = createAsyncThunk(
    'users/meetings',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await getScheduledMeetings();
            dispatch(setScheduledMeetings(response.data));
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

export type EvaluationFormData = {
    'form': {
        'evaluatedItems[]': string
    }
    'scheduleId': string
}

/**
 * GET user's scheduled meetings
 **/
export const getUserReviewList = createAsyncThunk(
    'users/meetings',
    async (type: number, { dispatch, rejectWithValue }) => {
        try {
            const response = await getScheduledMeetingEvaluations(type);
            const responseData = response?.data?.data?.reviewData
            dispatch(setReviewList(responseData));
            return responseData;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * Add evaluation to a certain meeting
 **/
export const addUserEvaluationMeeting = createAsyncThunk(
    'users/meetings/evaluation',
    async (data: EvaluationFormData, { rejectWithValue }) => {
        const { form, scheduleId } = data;
        const formData = new FormData();
        //Loop array form fields, convert key to snake_case
        _.forEach(form, function (value, key) {
            if (key.includes('[]')) {
                _.forEach(value, function (evaluationCodes) {
                    formData.append(_.snakeCase(key) + '[]', evaluationCodes)
                });
                return;
            }
            formData.append(_.snakeCase(key), value)
        });
        formData.append('schedule_id', scheduleId)
        try {
            const response = await addEvaluationMeeting(formData);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

export type Schedule = {
    schedule: string,
    application_uuid: string,
    fund_type: number,
    fund_title: string,
    specialist: string,
    specialist_image: string
}


export type Specialist = {
    uuid: string,
    officeName: string,
    postalCode: string,
    address: string,
    buildingName: string,
    officePr: string,
    supportedAreas: string,
    otherAddress: string,
    officeHistory: string,
    qualifications: string,
    interview: string,
    twitterUrl: string,
    facebookUrl: string,
    homepageUrl: string,
    prefectureCode: string,
    businessManualUrl: string,
    profileImageUrl: string,
    subProfileImageUrl: string
}

export type ReviewDetails = {
    schedule: string,
    fundTitle: string,
    scheduleId: string,
    role: string,
    specialist: {
        uuid: string,
        address: string,
        name: string,
        officeHistory: string,
        officeName: string,
        officePr: string,
        otherAddress: string,
        postalCode: string,
        profileImageUrl: string,
        qualifications: string,
        phone: string,
        email: string
    },
    evaluations: Evaluation[]
}

type Evaluation = {
    category: string,
    code: string,
    name: string
}

type MeetingState = {
    schedules: Schedule[],
    scheduledMeetingCount: number,
    meetingsFromPublicSupportCount: number,
    meetingsFromWorkRegulationCount: number,
    addMeetingStatus: string,
    reviewList: ReviewDetails[],
    fundList: string[]
}

type ReviewDetailsResponse = {
    schedule: string,
    fund_title: string,
    evaluations: Evaluation[],
    schedule_id: string,
    role: string,
    specialist: {
        address: string,
        name: string,
        office_history: string,
        office_name: string,
        office_pr: string,
        other_address: string,
        postal_code: string,
        profile_image_url: string,
        qualifications: string,
        user: {
            email: string
            profile: {
                created_at: string,
                firstname: string,
                firstname_furigana: string,
                lastname: string,
                lastname_furigana: string,
                phone: string,
            },
            uuid: string
        }
    }
}

const initialState: MeetingState = {
    schedules: [] as Schedule[],
    scheduledMeetingCount: 0,
    meetingsFromPublicSupportCount: 0,
    meetingsFromWorkRegulationCount: 0,
    addMeetingStatus: 'pending',
    reviewList: [] as ReviewDetails[],
    fundList: [] as string[]
};

export const meetingSlice = createSlice({
    name: 'meeting',
    initialState: initialState,
    reducers: {
        //reset add meeting status
        reset: (state: MeetingState) => {
            state.addMeetingStatus = 'pending';
        },
        setFundList: (state, { payload }) => {
            state.fundList = payload;
        },
        setScheduledMeetings: (state, { payload }) => {
            const { schedules, meetings_from_public_support_count, meetings_from_work_regulation_count, scheduled_meeting_count } = payload.data;
            state.schedules = schedules;
            state.scheduledMeetingCount = scheduled_meeting_count;
            state.meetingsFromPublicSupportCount = meetings_from_public_support_count;
            state.meetingsFromWorkRegulationCount = meetings_from_work_regulation_count;
        },
        setReviewList: (state, { payload }) => {
            state.reviewList = payload.map((reviewData: ReviewDetailsResponse) => ({
                schedule: reviewData.schedule,
                fundTitle: reviewData.fund_title,
                evaluations: reviewData.evaluations,
                scheduleId: reviewData.schedule_id,
                role: reviewData.role,
                specialist: {
                    name: reviewData?.specialist?.name,
                    email: reviewData?.specialist?.user?.email,
                    uuid: reviewData?.specialist?.user?.uuid,
                    officeName: reviewData?.specialist?.office_name,
                    phone: reviewData?.specialist?.user?.profile?.phone,
                    postalCode: reviewData?.specialist?.postal_code,
                    address: reviewData?.specialist?.address,
                    officePr: reviewData?.specialist?.office_pr,
                    otherAddress: reviewData?.specialist?.other_address,
                    officeHistory: reviewData?.specialist?.office_history,
                    qualifications: reviewData?.specialist?.qualifications,
                    profileImageUrl: reviewData?.specialist?.profile_image_url,
                }
            }));
        }
    },
    extraReducers: (builder) => {
        builder.addCase((addUserEvaluationMeeting.pending), (state) => {
            state.addMeetingStatus = 'pending';
        });
        builder.addCase((addUserEvaluationMeeting.fulfilled), (state) => {
            state.addMeetingStatus = 'success';
        });
        builder.addCase((addUserEvaluationMeeting.rejected), (state) => {
            state.addMeetingStatus = 'failed';
        });
    }
});

export const selectMeeting = (state: RootState) => state.meeting;

export const { setScheduledMeetings, setReviewList, reset, setFundList } = meetingSlice.actions;

