import { ChangeMeetingScheduleRequest, CreateMeetingApplicationRequest, GetCalendarScheduleRequest } from 'app/slice/companySlice';
import { getAxiosClientWithToken } from './index';

export const changePasswordUser = (current_password: string, new_password: string, new_password_confirmation: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/company/update-password`, {
        current_password,
        new_password,
        new_password_confirmation,
    });
};

export const changeMeetingScheduleAPI = (request: ChangeMeetingScheduleRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/company/meeting/${request.uuid}/change`, request);
};

export const unsubcribeUser = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/company/unsubscribe`);
};

export const checkScheduleUser = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/applications/check`);
};

export const getCalendarScheduleApi = (request: GetCalendarScheduleRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/calendar`, { params: request });
};

export const createMeetingApplicationApi = (params: CreateMeetingApplicationRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.post(`/company/applications/meeting`, params);
};

