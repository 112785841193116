import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getPolicyApplicationDetails, reset, selectPolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';
import { reset as companyReset } from 'app/slice/companySlice';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import KisokuMeetingDetails from 'components/kisoku/KisokuMeetingDetails';
import ConfirmCancelMeetingModal from 'components/dashboard/working_regulation/ConfirmCancelMeetingModal';
import ResultCancelMeetingModal from 'components/dashboard/working_regulation/ResultCancelMeetingModal';
import CancelMeetingModal from 'components/dashboard/working_regulation/CancelMeetingModal';
import ChangeScheduleModal from 'components/common/ChangeScheduleModal';
import CustomModal from 'components/common/CustomModal';
import errorIcon from 'assets/images/error.png';
import moment from 'moment';

type Params = {
    uuid: string;
};

const WorkingRegulationMeetingDetails = () => {
    const { loading, data } = useAppSelector(selectPolicyApplicationDetails);
    const dispatch = useAppDispatch();
    const { uuid } = useParams<Params>();
    const [isCancelMeetingVisible, setCancelMeetingVisible] = useState(false);
    const [isConfirmCancelModalVisible, setIsConfirmCancelModalVisible] = useState(false);
    const [isResultCancelModalVisible, setIsResultCancelModalVisible] = useState(false);
    const [isApplicationCreationErrorVisible, setIsApplicationCreationErrorVisible] = useState(false);
    const onCloseApplicationCreationErrorModal = () => setIsApplicationCreationErrorVisible(false);

    const [reasonCancelInput, setReasonCancelInput] = useState('');
    const [isChangeScheduleModalVisible, setIsChangeScheduleModalVisible] = useState(false);
    const onCloseChangeScheduleModal = () => setIsChangeScheduleModalVisible(false);

    const onOpenCancelMeeting = () => setCancelMeetingVisible(true);
    const onCloseCancelMeeting = () => setCancelMeetingVisible(false);
    const handleCloseResultCancelModal = () => setIsResultCancelModalVisible(false);
    const handleOpenConfirmCancelModal = () => {
        setCancelMeetingVisible(!isCancelMeetingVisible);
        setIsConfirmCancelModalVisible(!isConfirmCancelModalVisible);
    };
    const handleReturnConfirmCancelModal = () => {
        setIsConfirmCancelModalVisible(!isConfirmCancelModalVisible);
        setCancelMeetingVisible(!isCancelMeetingVisible);
    };
    const handleCloseConfirmCancelModal = () => setIsConfirmCancelModalVisible(false);
    const handleOpenResultCancelModal = () => {
        setIsConfirmCancelModalVisible(!isConfirmCancelModalVisible);
        setIsResultCancelModalVisible(!isResultCancelModalVisible);
    };

    // Get policy application details
    useEffect(() => {
        // Reset data to make sure previous state is not displayed
        dispatch(reset());

        if (uuid) {
            dispatch(getPolicyApplicationDetails(uuid));
        }
    }, [dispatch, uuid]);

    const openChangeScheduleModal = () => {
        const today = moment();
        const formattedCurrentDate = today.format('YYYY.MM.DD');
        const currentScheduleDate = data.schedule;
        const formattedCurrentScheduleDate = currentScheduleDate!.replace('年', '.').replace('月', '.').replace('日', '');

        dispatch(companyReset());

        if (formattedCurrentDate === formattedCurrentScheduleDate) {
            setIsApplicationCreationErrorVisible(true);
        } else {
            setIsChangeScheduleModalVisible(true);
        }
    };

    const handleReasonCancelInput = (value: string) => {
        setReasonCancelInput(value);
    };

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <>
                    <div className='content kisoku-meetings'>
                        <KisokuMeetingDetails
                            title={'面談詳細'}
                            data={data}
                            primaryOnClick={openChangeScheduleModal}
                            secondaryOnClick={onOpenCancelMeeting}
                        />

                        {/* Modal to change Kisoku meeting schedule */}
                        <ChangeScheduleModal
                            isVisible={isChangeScheduleModalVisible}
                            title='面談予約 日程変更'
                            reasonText={
                                '・変更を希望する面談日時を、下のカレンダーから新たに選択してください。\n ・面談予約枠は、本日より7営業日以降の日程から選択可能です。'
                            }
                            uuid={data?.uuid}
                            oldSchedule={data?.scheduleDatetime}
                            onClose={onCloseChangeScheduleModal}
                            onOpenChangeScheduleModal={openChangeScheduleModal}
                        />
                    </div>

                    <CancelMeetingModal
                        isVisible={isCancelMeetingVisible}
                        uuid={uuid!}
                        onClose={onCloseCancelMeeting}
                        onClickConfirm={handleOpenConfirmCancelModal}
                        handleReasonCancelInput={handleReasonCancelInput}
                    />
                    <ConfirmCancelMeetingModal
                        uuid={uuid!}
                        isVisible={isConfirmCancelModalVisible}
                        onClose={handleCloseConfirmCancelModal}
                        onClickReturn={handleReturnConfirmCancelModal}
                        onClickConfirm={handleOpenResultCancelModal}
                        application={data}
                        reasonCancelInput={reasonCancelInput}
                    />
                    <ResultCancelMeetingModal
                        uuid={uuid!}
                        isVisible={isResultCancelModalVisible}
                        onClose={handleCloseResultCancelModal}
                        application={data}
                    />

                    {/*Modal when an applications cannot be changed on the day*/}
                    <CustomModal
                        isVisible={isApplicationCreationErrorVisible}
                        onClose={onCloseApplicationCreationErrorModal}
                        title=''
                        icon={errorIcon}
                        text='当日に予約変更はできません。当日の変更連絡は大同生命の営業担当者までご連絡ください。'
                        showCancelButton={false}
                        showConfirmButton={true}
                        confirmButtonText='閉じる'
                        confirmOnClick={onCloseApplicationCreationErrorModal}
                    />   
                </>
            )}
        </>
    );
};

export default WorkingRegulationMeetingDetails;
