import { Card, Space } from 'antd';
import error_avatar from 'assets/images/error_diagnosis_avatar.png';
import DiagnoseNotApplicable from './DiagnoseNotApplicable';

const DiagnoseResultInValid = () => {
    return (
        <>
            <Card className='diagnose-list'>
                <Space size={12}>
                    <img className='diagnosis-avatar' src={error_avatar} alt='avatar' width={96} height={96} />
                    <h3 className='header-title-error'>
                        <p>前頁で診断（チェック）いただいた内容では、以下の公的資金の受給要件には該当していません。</p>
                    </h3>
                </Space>

                <div>
                    <div className='info-title-error'>
                        <p className='info'>
                            公的支援制度を活用するためには、事前にしっかりとした計画と準備を行い申請要件を満たすことが必要となります。
                        </p>
                    </div>

                    <div className='info-subtitle'>
                        <p>公的資金の詳細・要件等につきましては、「参考ページ（外部リンク）」先のお問い合わせ窓口をご利用ください。</p>
                    </div>
                </div>
                <DiagnoseNotApplicable />
            </Card>
        </>
    );
};

export default DiagnoseResultInValid;
