import { Col, Row } from 'antd';
import DiagnoseResultInValid from './DiagnoseResultInvalid';
import { useLocation } from 'react-router-dom';
import DiagnoseResultValid from './DiagnoseResultValid';
import { useEffect, useState } from 'react';
import { FundsLocationState } from 'pages/kouteki/DiagnoseResult';
import { FundType } from 'enums';

type Props = {
    handleDiagnoseScheduleSubmit: () => void;
};

const MultipleResultHeader = (props: Props) => {
    const { handleDiagnoseScheduleSubmit } = props;
    const { funds } = useLocation().state as FundsLocationState;
    const fundsWithoutBasicRequirements = funds.filter((fund) => fund.code);
    const basicCriteriaChecked = funds.find((code) => code.title === '基本確認項目')?.result ?? true;
    const additionalCriteria = fundsWithoutBasicRequirements.some((fund) => fund.result);
    const additionalCriteriaAllChecked = fundsWithoutBasicRequirements.every((fund) => fund.result);
    const isNoneChecked = fundsWithoutBasicRequirements.some((fund) => !fund.result);
    const hasGrant = funds.some((e) => e.type === FundType.GRANT);
    const [totalFundApplicable, setTotalFundApplicable] = useState(0);
    const [totalFundNotApplicable, setTotalFundNotApplicable] = useState(0);

    useEffect(() => {
        let applicable = 0;
        let notApplicable = 0;

        if (additionalCriteria) {
            funds
                .filter((fund) => fund.title !== '基本確認項目')
                .forEach((fund) => {
                    if (basicCriteriaChecked && fund.result === true) {
                        applicable++;
                    } else if (basicCriteriaChecked && additionalCriteriaAllChecked) {
                        applicable++;
                    } else {
                        notApplicable++;
                    }
                });
        }

        if (!additionalCriteria) {
            notApplicable = funds.length;
            applicable = 0;
        }

        if (!additionalCriteria && hasGrant) {
            notApplicable = funds.length - 1;
            applicable = 0;
        }

        setTotalFundApplicable(applicable);
        setTotalFundNotApplicable(notApplicable);
    }, [funds, additionalCriteria, hasGrant, basicCriteriaChecked, additionalCriteriaAllChecked]);

    return (
        <>
            <div>
                <Row gutter={16} className='align-items-center funds-result'>
                    <Col>
                        <p>
                            活用できる可能性のある公的資金… <strong className='potential-funds'> {totalFundApplicable} 件</strong>
                        </p>
                    </Col>
                    <Col>
                        <p>
                            該当しない公的資金 … <strong className='invalid-funds'> {totalFundNotApplicable} 件</strong>
                        </p>
                    </Col>
                </Row>

                <Row>
                    <p className='sub-header'>
                        <h4>本診断は簡易診断です。</h4>
                        <span>
                            {/* For specific application requirements, etc., please use the "Expert Consultation Desk" or refer to the "Reference Page" Please use the contact point above. */}
                            具体的な申請要件などについては、「専門家相談窓口」をご利用いただくか、「参考ページ（外部リンク）」先の管轄機関にお問い合わせください。
                        </span>
                    </p>
                </Row>

                {/* If none of the checkboxes are checked */}
                {!additionalCriteria || !basicCriteriaChecked ? (
                    <DiagnoseResultInValid />
                ) : (
                    <>
                        {hasGrant ? (
                            <div>
                                {/* Josekin scenario : All basic criteria and additional criteria should be checked to be valid. */}
                                {basicCriteriaChecked && additionalCriteria && (
                                    <DiagnoseResultValid handleDiagnoseScheduleSubmit={handleDiagnoseScheduleSubmit} />
                                )}

                                {basicCriteriaChecked && isNoneChecked && <DiagnoseResultInValid />}
                            </div>
                        ) : (
                            <div>
                                {/* Hojokin scenario : All criteria should be checked to be valid. */}
                                {additionalCriteriaAllChecked && (
                                    <DiagnoseResultValid handleDiagnoseScheduleSubmit={handleDiagnoseScheduleSubmit} />
                                )}

                                {additionalCriteria && isNoneChecked && (
                                    <div>
                                        <DiagnoseResultValid handleDiagnoseScheduleSubmit={handleDiagnoseScheduleSubmit} />
                                        <DiagnoseResultInValid />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default MultipleResultHeader;
