import React from 'react';
import { Button, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { reset, selectSuccessModal } from 'app/slice/successSlice';
import { useNavigate } from 'react-router-dom';

/**
 * Component used for all Success Modal
 * @return html
 **/
const SuccessModal = () => {
    const navigate = useNavigate();
    const {
        image = '',
        title,
        message,
        withButton,
        isShow,
        buttonLink,
        buttonTitle,
    } = useAppSelector(selectSuccessModal);
    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(reset());
    };

    const onConfirm = () => {
        onClose();

        if (buttonLink) navigate(buttonLink);
    };

    return (
        <>
            <Modal className='success-modal'
                   visible={isShow}
                   onCancel={onClose}
                   closable={true}
                   maskClosable={false}
                   keyboard={false}
                   footer={null}
            >
                <div className='title'>{title}</div>
                {image && <img src={image} alt={'chukidan-icon'} />}
                <div className='message'>{message}</div>
                {withButton && (
                    <Button onClick={onConfirm} className='mt-3 w-75 submit-button'>{buttonTitle}</Button>
                )}
            </Modal>
        </>
    );
};

export default SuccessModal;
