import { Button, Calendar, Col, ConfigProvider, Radio, RadioChangeEvent, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import locale from 'antd/lib/locale/ja_JP';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { GET_CALENDAR_SCHEDULES, getCalendarSchedule, selectCompany } from 'app/slice/companySlice';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import { selectAuth } from 'app/slice/authSlice';
import scheduleSelectable from 'assets/images/schedule_selectable.png';
import scheduleFull from 'assets/images/schedule_full.png';
import scheduleNotAvailable from 'assets/images/schedule_not_available.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

type CalendarHeaderConfig = {
    value: Moment;
    type: CalendarMode;
    onChange: (date: Moment) => void;
    onTypeChange: (type: CalendarMode) => void;
};

type Props = {
    scheduleDate: string;
    scheduleTime: string;
    fundType?: number;
    setScheduleDate: (updateScheduleDate: string) => void;
    setScheduleTime: (updateScheduleTime: string) => void;
};

const CalendarSchedule = (props: Props) => {
    const { scheduleDate, scheduleTime, setScheduleDate, setScheduleTime, fundType } = props;
    const { calendar, success, type } = useAppSelector(selectCompany);
    const { token } = useAppSelector(selectAuth);
    const [hasSelectedDate, setHasSelectedDate] = useState(false);
    const dispatch = useAppDispatch();
    const [currentAvailableTime, setCurrentAvailableTime] = useState<string[]>([]);
    const [selectedDateYearMonth, setSelectedDateYearMonth] = useState<string>(moment().format('YYYY-MM'));
    const [initialDate, setInitialDate] = useState<Moment>();
    const hours = [10, 11, 12, 13, 14, 15, 16];
    const [calendarType, setCalendarType] = useState(3);

    //set calendar type depending on fund type
    useEffect(() => {
        switch (fundType) {
            case 3:
                setCalendarType(2);
                break;
            case 4:
                setCalendarType(1);
                break;
            default:
                setCalendarType(3);
                break;
        }
    }, [fundType]);

    // initial set schedule date for today
    useEffect(() => {
        if (token) {
            const today = moment();
            let initialDefaultDate = today;
            let formattedDate;
            let selectedYearMonth;

            // Check if current date is in March 2025
            if (today.isSame('2025-03', 'month')) {
                // Set initial date to April 1st, 2025 and year month to 2025-04 for deployment only.
                initialDefaultDate = moment('2025-04-01');
                formattedDate = initialDefaultDate.format('YYYY年MM月DD日');
                selectedYearMonth = moment('2025-04').format('YYYY-MM');
            }

            formattedDate = initialDefaultDate.format('YYYY年MM月DD日');
            selectedYearMonth = initialDefaultDate.format('YYYY-MM');

            // Set initial date state for calendar component
            setInitialDate(initialDefaultDate);
            setScheduleDate(formattedDate);
            setSelectedDateYearMonth(selectedYearMonth);
        }
    }, [token, dispatch]);

    // When the month changes, fetch the schedule for that month
    useEffect(() => {
        if (scheduleDate !== '--') {
            dispatch(
                getCalendarSchedule({
                    month: selectedDateYearMonth,
                    type: calendarType,
                })
            );
        }
    }, [scheduleDate, dispatch, type, selectedDateYearMonth]);

    // Trigger onDateSelect today when the page is loaded, primary to load time schedules
    useEffect(() => {
        const today = moment();
        let formattedDate: string;
        //Check if current date is in March 2025
        if (today.isSame('2025-03', 'month')) {
            // Set formatted date to April 1st, 2025 to match the schedule date.
            formattedDate = moment('2025-04-01').format('YYYY年MM月DD日');
        } else {
            formattedDate = today.format('YYYY年MM月DD日');
        }

        //execute onDateSelect if the day is today
        if (formattedDate === scheduleDate && success && type === GET_CALENDAR_SCHEDULES && calendar.availableSchedules.length !== 0) {
            onDateSelect(today);
            setHasSelectedDate(true);
        }
    }, [success, type, calendar, scheduleDate]);

    const onDateSelect = (date: Moment) => {
        let selectedDate = date;
        let formattedSelectedDate: string;
        let formattedDate: string;
        let selectedYearMonth: string;

        //Check if current date is in March 2025
        if (date.isSame('2025-03', 'month')) {
            // Set selected date to April 1st, 2025 to match the schedule date.
            selectedDate = moment('2025-04-01');
        }

        formattedSelectedDate = selectedDate.format('YYYY-MM-DD');
        formattedDate = selectedDate.format('YYYY年MM月DD日');
        selectedYearMonth = selectedDate.format('YYYY-MM');

        const schedule = calendar.availableSchedules.find((schedule) => schedule.date === formattedSelectedDate);
        // Update schedule date display
        if (hasSelectedDate) {
            setScheduleDate(formattedDate);
        }
        setScheduleTime('--');
        setCurrentAvailableTime([]);
        setInitialDate(selectedDate);
        setSelectedDateYearMonth(selectedYearMonth);

        if (schedule) {
            setCurrentAvailableTime(schedule.time);
        }
    };

    // Disable weekends and holidays
    const disabledDate = (current: Moment) => {
        const today = moment();

        // Disable Sundays, Saturdays
        if (current.isBefore(today, 'day') || current.day() === 0 || current.day() === 6) {
            return true;
        }

        // Disable prev month and next month and next, next month
        if (today.month() > current.month() || today.month() + 2 < current.month()) {
            return true;
        }

        // Disable from selectableFrom and selectableUntil
        if (!current.isBetween(calendar.selectableFrom, calendar.selectableUntil, 'day', '[]')) {
            return true;
        }

        // Check if the current date matches any holiday
        const isHoliday = calendar.holidays.some((holiday) => {
            return current.isBetween(holiday.start, holiday.end, 'day', '[]');
        });

        // Disable holidays
        if (isHoliday) {
            return true;
        }

        return false;
    };

    // Check dates within the range of the current calendar
    const isDateInRange = (date: Moment) => {
        const start = moment(calendar.selectableFrom);
        const end = moment(calendar.selectableUntil);
        return date.isBetween(start, end, 'day', '[]');
    };

    const dateCellRender = (date: Moment) => {
        const today = moment();

        // Check if the current date matches any holiday
        const isHoliday = calendar.holidays.some((holiday) => {
            return date.isBetween(holiday.start, holiday.end, 'day', '[]');
        });

        // Check if the current date matches any available schedules
        const isAvailable = calendar.availableSchedules.some((schedule) => {
            return date.isSame(moment(schedule.date), 'day') && schedule.time.length > 0;
        });

        // Check if the current date matches any available dates within the date range that has no available schedules
        const isUnavailable =
            isDateInRange(date) &&
            !calendar.availableSchedules.some((schedule) => {
                return date.isSame(moment(schedule.date), 'day') && schedule.time.length > 0;
            });

        // Do not display icons inside date if it's a weekend, holiday, before today, or before of the current month
        if (date.day() !== 0 && date.day() !== 6 && !isHoliday && !date.isBefore(today, 'day')) {
            if (isAvailable) {
                return (
                    <div className='schedule-checker-icon'>
                        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <circle cx='9' cy='9' r='8' strokeWidth='2' />
                        </svg>
                    </div>
                );
            }

            if (isUnavailable) {
                return (
                    <div className='schedule-checker-icon'>
                        <img src={scheduleFull} alt='logo' />
                    </div>
                );
            }
        }
        return null;
    };

    const CalendarHeader = (config: CalendarHeaderConfig) => {
        const today = moment();
        let currentMonthIndex = today.month();
        let configMonthValueIndex = config.value.month();
        let configMonthRange = 2;

        //Check if current date is in March 2025
        if (today.isSame('2025-03', 'month')) {
            // Set month index to 3 if March 2025 to disable March month prev.
            currentMonthIndex = moment().month() + 1;
            configMonthRange = 1;
        }

        const onClickPrev = () => {
            let newValue = config.value.clone().add(-1, 'month');

            // Disable prev month
            if (currentMonthIndex <= newValue.month()) {
                config.onChange(newValue);
            }
        };

        const onClickNext = () => {
            let newValue = config.value.clone().add(1, 'month');

            // Disable next and next, next month
            if (currentMonthIndex + configMonthRange >= newValue.month()) {
                config.onChange(newValue);
            }
        };

        return (
            <Row justify='start' className='mb-3 align-items-center'>
                <Col flex='170px' className='month-picker'>
                    <Button onClick={onClickPrev} disabled={currentMonthIndex >= configMonthValueIndex}>
                        <LeftOutlined />
                    </Button>
                    <span className='month-picker-text'>{config.value.format('YYYY年M月')}</span>
                    <Button onClick={onClickNext} disabled={currentMonthIndex + configMonthRange <= configMonthValueIndex}>
                        <RightOutlined />
                    </Button>
                </Col>
                <Col flex='auto' className='schedule-summary'>
                    <div>
                        <span className='schedule-label'>選択した日時：</span>
                        {scheduleTime !== '--' ? (
                            <span className='schedule-datetime visible'>
                                {scheduleDate} {scheduleTime}
                            </span>
                        ) : (
                            <span className='schedule-datetime invisible'>XXXX年XX月XX日 XX:XX~XX:XX</span>
                        )}
                    </div>
                </Col>
                <Col flex='300px' className='schedule-icons mt-1 text-end'>
                    <img src={scheduleSelectable} alt='logo' />
                    <span>選択可</span>
                    <img src={scheduleFull} alt='logo' />
                    <span>予約枠無し</span>
                    <img src={scheduleNotAvailable} alt='logo' />
                    <span>選択期間外</span>
                </Col>
            </Row>
        );
    };

    const handleScheduleTime = (e: RadioChangeEvent) => {
        setScheduleTime(e.target.value);
    };

    return (
        <Row justify='start' gutter={[16, 0]}>
            <Col span={24} md={19}>
                <div>
                    <ConfigProvider locale={locale}>
                        <Calendar
                            className='schedule-calendar'
                            disabledDate={disabledDate}
                            dateCellRender={dateCellRender}
                            headerRender={CalendarHeader}
                            onSelect={onDateSelect}
                            value={initialDate}
                        />
                    </ConfigProvider>
                </div>
            </Col>
            <Col span={24} md={5}>
                <Radio.Group
                    className='schedule-time mt-md-5'
                    value={scheduleTime}
                    onChange={handleScheduleTime}
                    options={hours.map((hour) => {
                        const hourFormat = `${hour}:00:00`;
                        const timeLabel = `${hour}:00~${hour}:30`;
                        const isDisabled = !currentAvailableTime.includes(hourFormat);
                        return {
                            value: timeLabel,
                            disabled: isDisabled,
                            label: timeLabel,
                        };
                    })}
                />
            </Col>
        </Row>
    );
};

export default CalendarSchedule;
