import React, { useEffect, useState } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import TextArea from 'antd/lib/input/TextArea';
import { Button, Col, Divider, Form, Radio, Row, Typography } from 'antd';
import { ExclamationCircleFilled, LeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import ScheduleSummaryModal from 'components/kouteki/ScheduleSummaryModal';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { createMeetingApplication, resetUpdateSuccess, selectCompany } from 'app/slice/companySlice';
import { FundsLocationState } from './DiagnoseResult';
import { Rule } from 'antd/lib/form';
import CalendarSchedule from 'components/common/CalendarSchedule';

const { Text } = Typography;

export type FormType = {
    remarks: string;
    daido_life: number;
};

const required: Rule[] = [
    {
        required: true,
        message: (
            <>
                <ExclamationCircleFilled /> 必須項目です
            </>
        ),
    },
];

const DiagnoseResultSchedule = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    // use states
    const [scheduleDate, setScheduleDate] = useState<string>('--');
    const [scheduleTime, setScheduleTime] = useState<string>('--');
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isSummaryVisible, setSummaryVisible] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([
        { name: 'トップページ', url: '/kouteki' },
        { name: '検索', url: '/kouteki/funds/search' + localStorage.getItem('searchPath') },
        { name: '活用可能性の診断結果', url: '/kouteki/funds/diagnose' },
        { name: '面談申し込み', url: '' },
    ]);

    // selectors
    const { application, loading, type } = useAppSelector(selectCompany);
    const { updateSuccess } = useAppSelector(selectCompany);

    const handleBackClick = () => {
        navigate('/kouteki/funds/diagnose', { state: { funds: funds, fromSchedule: true } });
    };

    const { funds } = (useLocation().state as FundsLocationState) || {};

    const onCloseSummaryModal = () => {
        setSummaryVisible(false);
        setIsSubmitted(false);
    };

    const onOpenSummaryModal = () => {
        //Validate error before opening modal.
        if (scheduleTime === '--') {
            handleSubmitError();
        } else {
            setSummaryVisible(true);
            setHasSubmitted(true);
        }
    };

    // Update breadcrumbs to add state value for specific url
    useEffect(() => {
        if (funds) {
            setBreadcrumbs((prev) =>
                prev.map((crumb, index) => (index === 2 ? { ...crumb, state: { funds, fromSchedule: true } } : crumb))
            );
        }
    }, [funds]);

    // Format full selected schedule for summary
    const summarySchedule = () => {
        return scheduleDate + ' ' + scheduleTime;
    };

    // Redirect if funds state does not have value
    useEffect(() => {
        if (!funds) {
            navigate('/kouteki/funds/search');
        }
    }, [funds, navigate]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(resetUpdateSuccess());
            navigate('/kouteki/funds/diagnose/schedule/finished', { state: { application: application } });
        }
    }, [updateSuccess, navigate, application, dispatch]);

    const handleSubmit = () => {
        setIsSubmitted(true);
        const formValues = form.getFieldsValue();
        const fundsForm = funds?.map((fund) => ({
            code: fund.code,
            remarks: formValues.remarks,
        }));
        const formattedDate = scheduleDate.replace('年', '-').replace('月', '-').replace('日', '');
        const [startTime] = scheduleTime.split('~');

        dispatch(
            createMeetingApplication({
                funds: fundsForm,
                meeting: {
                    schedule: `${formattedDate} ${startTime}:00`,
                    daido_joins: formValues.daido_join,
                    calendar_type: 1,
                },
            })
        );
    };

    const handleSubmitError = () => {
        //only scroll to top of the page if no schedule was selected in the calendar.
        if (scheduleTime === '--') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
        setHasSubmitted(true);
    };

    return (
        <>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs} />
            </section>
            <section className='content diagnose-result-schedule-container'>
                <div className='title'>面談申し込み</div>
                <div className='step-title'>
                    <span>STEP 1</span>
                    カレンダーより面談希望日と時間帯を選択してください
                    <div className='list'>※選択可能な期間は、本日より7営業日後～1.5ヵ月先までの日程となります。（土日祝日を除く）</div>
                </div>
                {scheduleTime === '--' && hasSubmitted && (
                    <div className='calendar-error-message'>
                        <ExclamationCircleFilled /> 面談の希望日選択は必須項目です。
                    </div>
                )}
                <div className='schedule-calender-card'>
                    <CalendarSchedule
                        setScheduleDate={setScheduleDate}
                        setScheduleTime={setScheduleTime}
                        scheduleDate={scheduleDate}
                        scheduleTime={scheduleTime}
                        fundType={funds[0]?.type}
                    />
                </div>
                <div className='step-title'>
                    <span>STEP 2</span>
                    下部のフォームに必要情報を入力して確認画面へ進んでください
                </div>
                <Form layout='vertical' onFinish={onOpenSummaryModal} onFinishFailed={handleSubmitError} form={form}>
                    <div className='confirmation-form'>
                        <Divider orientation='left' />

                        <Row gutter={[16, 24]}>
                            <Col span={24} md={8}>
                                <div>相談希望の公的資金</div>
                            </Col>
                            <Col span={24} md={16}>
                                {funds?.map((fund) => (
                                    <div>{fund.title}</div>
                                ))}
                            </Col>
                        </Row>
                        <Divider orientation='left' />
                        <Row gutter={[16, 24]}>
                            <Col span={24} md={8}>
                                <div className='mt-md-4'>
                                    希望される相談内容の詳細・目的などをご入力ください
                                    <Text type={'danger'}>
                                        （<span className='required-mark'>必須</span>）
                                    </Text>
                                </div>
                            </Col>
                            <Col span={24} md={16}>
                                <div>
                                    <Form.Item name='remarks' rules={required}>
                                        <TextArea
                                            maxLength={500}
                                            rows={4}
                                            placeholder={
                                                'ご相談したい内容を具体的にご入力ください。 (500文字以内)\n(例: 申請要件を満たしているか詳しく聞きたい)'
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Divider orientation='left' />
                        <Row gutter={[16, 24]}>
                            <Col span={24} md={8}>
                                <div>
                                    大同営業担当者の同席について
                                    <Text type={'danger'}>
                                        （<span className='required-mark'>必須</span>）
                                    </Text>
                                </div>
                            </Col>
                            <Col span={24} md={16}>
                                <div>
                                    <Form.Item name='daido_join' rules={required}>
                                        <Radio.Group>
                                            <Radio value={1}>同席を希望します</Radio>
                                            <Radio value={0}>同席を希望しません</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Divider orientation='left' />
                    </div>
                    <div className='schedule-btn-group w-100'>
                        <div className='mb-4'>
                            <Button type='default' onClick={handleBackClick}>
                                <LeftOutlined className='arrow-icon left-outline' />
                                <span>戻る</span>
                            </Button>
                        </div>
                        <div className='schedule-btn-section w-100'>
                            <div className='text-center'>
                                <Button type='primary' htmlType='submit' className={'w-200px'}>
                                    内容を確認
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
                <ScheduleSummaryModal
                    loading={loading}
                    isSubmitted={isSubmitted}
                    isVisible={isSummaryVisible}
                    onClose={onCloseSummaryModal}
                    form={form}
                    funds={funds}
                    schedule={summarySchedule()}
                    onSubmit={handleSubmit}
                    type={type}
                />
            </section>
        </>
    );
};

export default DiagnoseResultSchedule;
