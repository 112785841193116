import React from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import TermsOfService from 'components/landing/TermsOfService';

/**
 * Props for Breadcrumb
 */
const breadcrumbs: Breadcrumb[] = [
    {
        name: 'トップページ',
        url: '/kisoku',
    },
    {
        name: '利用規約',
        url: '/kisoku/terms-of-service',
    },
];

const WorkRegulationTermsOfService = () => {
    return (
        <>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs}/>
            </section>
            <section className='static-content'>
                <TermsOfService/>
            </section>
        </>
    );
};

export default WorkRegulationTermsOfService;
