import React from 'react';
import { Button, Col, FormInstance, Modal, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { CREATE_MEETING_APPLICATION } from '../../app/slice/companySlice';

type Props = {
    onClose: () => void,
    form: FormInstance,
    funds: { code: string; title: string; allowConsultation: boolean; type: number; url: string; result: boolean }[],
    onSubmit: () => void;
    schedule: string,
    isVisible: boolean,
    loading: boolean
    isSubmitted: boolean,
    type: string,
}

const ScheduleSummaryModal = (props: Props) => {
    const { isVisible, onSubmit, onClose, form, funds, schedule, loading, isSubmitted, type } = props;
    const formValues = form.getFieldsValue();

    return (
        <Modal visible={isVisible} footer={null} width={800} onCancel={onClose}>
            <section className='schedule-modal-container'>
                <div className='text-center'>
                    <div className='mb-3 schedule-title-modal'>下記の内容でオンライン面談を予約しますか</div>
                </div>
                <div className='schedule-content-modal'>
                    <Row gutter={[16, 24]}>
                        <Col md={6}>
                            <div>面談日程</div>
                        </Col>
                        <Col md={18}>
                           <div className='summary-schedule'>{schedule}</div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col md={6}>
                            <div>面談内容</div>
                        </Col>
                        <Col md={18}>
                        {funds?.map((fund) =><div>{fund.title}</div>)}
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col md={6}>
                            <div>相談したい内容詳細</div>
                        </Col>
                        <Col md={18}>
                            {formValues.remarks}
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col md={6}>
                            <div>大同生命担当者</div>
                        </Col>
                        <Col md={18}>
                            {formValues.daido_join ? '同席を希望します' : '同席を希望しません'}
                        </Col>
                    </Row>
                </div>
                <div className='schedule-btn-group w-100'>
                    <div>
                        <Button type='primary' className='btn-primary-outlined w-180px' onClick={onClose}>
                            <LeftOutlined className='arrow-icon left-outline'/>
                            <span>修正する</span>
                        </Button>
                    </div>
                    <div className='schedule-btn-section w-100'>
                        <Button type='primary'
                                loading={type === CREATE_MEETING_APPLICATION && loading}
                                disabled={isSubmitted || schedule.includes('--')}
                                onClick={onSubmit} className='w-300px'>
                            予約する
                        </Button>
                    </div>
                </div>
            </section>
        </Modal>
    );
};

export default ScheduleSummaryModal;
