import { ChangeEventHandler, useEffect, useState } from 'react';
import { Col, Empty, Input, Pagination, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getApplications, getApplicationStatusTypes, selectApplications } from 'app/slice/applicationListSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParamsState } from 'lib/utils';
import { setSearchQuery } from 'app/slice/searchQuerySlice';
import ConsultationListCardUpdateVersion from 'components/consultation/ConsultationListCardUpdateVersion';

const ConsultationList = () => {
    // Search constant
    const { Search } = Input;

    // Constants for Application List Display
    const { applications, applicationStatusTypes, pagination } = useAppSelector(selectApplications);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter Function Constants
    const [keywords] = useParamsState('keyword', '');
    const [page, setPage] = useParamsState<number>('page', 1, parseInt);
    const [selectedStatus] = useParamsState<number>('status', -1, parseInt);
    const [sort, setSort] = useState<string | undefined>('');
    const [keywordChange, setKeywordChange] = useState<string>();

    const onChangeKeyword: ChangeEventHandler = (event: any) => {
        setKeywordChange(event.target.value);
    };

    const onSearch = (value: string) => {
        searchParams.set('page', '1');
        searchParams.set('keyword', value);
        setSearchParams(searchParams);
    };

    const onChangeStatus = (value: number) => {
        searchParams.set('page', '1');
        searchParams.set('status', value.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        setKeywordChange(keywords);
    }, [keywords]);

    useEffect(() => {
        dispatch(setSearchQuery('?' + searchParams.toString()));
    }, [dispatch, searchParams]);

    // Get Applications and Application Statuses
    useEffect(() => {
        dispatch(
            getApplications({
                keywords: keywords,
                page: page,
                status: selectedStatus,
                sortBy: sort,
            })
        );

        dispatch(getApplicationStatusTypes());
    }, [dispatch, keywords, navigate, page, selectedStatus, sort]);

    // Return scroll to top on page change only
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [page]);

    return (
        <div className='content consultation-list'>
            <div className='header-container'>
                <div className='title'>面談依頼一覧</div>
                <div className='page-actions'>
                    <div className='subtitle'>累計：{pagination.total ? pagination.total : 0}件</div>
                </div>
            </div>
            <Row gutter={10}>
                <Col span={8} md={5} xl={3} className='subtitle'>
                    ステータス
                </Col>
                <Col span={16} md={19} xl={7}>
                    <Select className='page-actions-items page-actions-items-select me-2' value={selectedStatus} onChange={onChangeStatus}>
                        <Select.Option value={-1}>表示項目</Select.Option>
                        {applicationStatusTypes.map((row) => (
                            <Select.Option key={row.code} value={row.code}>
                                {row.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8} md={5} xl={3} className='subtitle'>
                    文字検索
                </Col>
                <Col span={16} md={19} xl={9}>
                    <Search
                        placeholder='入力してください'
                        onSearch={onSearch}
                        value={keywordChange}
                        onChange={onChangeKeyword}
                        className='page-actions-items page-actions-items-search'
                    />
                </Col>
            </Row>
            {applications.length === 0 ? (
                <div className='my-5'>
                    <Empty />
                </div>
            ) : (
                <ConsultationListCardUpdateVersion setSort={setSort} />
            )}
            <div className='text-center consultation-list-pagination'>
                {pagination?.total > 0 && (
                    <Pagination
                        className='d-inline float-center'
                        defaultPageSize={pagination.perPage}
                        current={page}
                        total={pagination.total}
                        onChange={setPage}
                        showSizeChanger={false}
                    />
                )}
            </div>
        </div>
    );
};

export default ConsultationList;
