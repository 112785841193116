import React from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
    isVisible: boolean;
    scheduleLabel: string;
    onClose: () => void;
};

const AppointmentBookedModal = (props: Props) => {
    const { isVisible, scheduleLabel, onClose } = props;
    return (
        <Modal className='appointment-booked-modal' visible={isVisible} onCancel={onClose} footer={null}>
            <div className='title'>オンライン面談の予約が完了しました</div>

            <div className='subtitle'>
                <p className='subtitle-top'>下記の日程でオンライン面談予約をお受けしました。</p>
                <p className='subtitle-bottom'>面談URLはマイページの「就業規則診断サービス」内、「面談依頼一覧」からご確認ください。</p>
            </div>

            <div className='appointment-booked-div'>
                <Row className='appointment-booked-row'>
                    <Col span={8} className='appointment-booked-label'>
                        面談日程
                    </Col>

                    <Col span={16} className='appointment-booked-date'>
                        {scheduleLabel}
                    </Col>
                </Row>
            </div>

            <div className='action-button'>
                <Button className='btn-close' onClick={onClose}>
                    <h3 className='btn-close-text'>
                        <CloseOutlined className='btn-icon' />
                        閉じる
                    </h3>
                </Button>
            </div>
        </Modal>
    );
};

export default AppointmentBookedModal;
