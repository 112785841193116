import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { Button, Checkbox, Form, Skeleton } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { FundRequirement, getFundDetails, selectFundDetails } from 'app/slice/fundDetailsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';

import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FundsLocationState } from 'pages/kouteki/DiagnoseResult';
import Highlight from 'components/common/Highlight';
import moment from 'moment';
import { selectAuth } from 'app/slice/authSlice';
import { track } from 'app/slice/trackerSlice';
import { useParamsState } from 'lib/utils';

type Params = {
    code: string;
};

const FundDetails = () => {
    const navigate = useNavigate();
    const params = useParams<Params>();
    const dispatch = useAppDispatch();
    const { loading, success, data } = useAppSelector(selectFundDetails);
    const { uuid } = useAppSelector(selectAuth);
    const [state, setState] = useState<FundsLocationState>({ funds: [] });

    const [keyword] = useParamsState('keyword', '');

    const onChange = ({ title }: FundRequirement) => {
        return (checkedValue: CheckboxValueType[]) => {
            const index = state.funds.findIndex((fund) => fund.title === title);
            const fund = data.fundRequirements.find((fund) => fund.title === title);
            state.funds[index].result = fund?.requirements.length === checkedValue.length;
            setState(state);
        };
    };

    const onSubmit = () => {
        dispatch(track({ type: 1, uuid, funds: state.funds.map((fund) => fund.code) }));
        navigate('/kouteki/funds/diagnose', { state });
    };

    useEffect(() => {
        if (params.code) {
            dispatch(getFundDetails(params.code));
        }
    }, [dispatch, params.code]);

    useEffect(() => {
        if (success) {
            setState({
                funds: data.fundRequirements.map((row) => ({
                    code: data.code,
                    title: row.title,
                    allowConsultation: data.allowConsultation,
                    type: data.type,
                    url: data.url,
                    result: false,
                    requirements: row.requirements,
                })),
            });
        }
    }, [data.allowConsultation, data.code, data.fundRequirements, data.type, data.url, dispatch, params.code, success]);

    const breadcrumbs: Breadcrumb[] = [
        { name: 'トップページ', url: '/kouteki' },
        { name: data.title, url: '' },
    ];

    return (
        <Highlight search={keyword}>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs} />
            </section>
            <section className='content search-funds-details'>
                {loading ? (
                    <Skeleton active />
                ) : (
                    <Form onFinish={onSubmit}>
                        <div className='title'>
                            {data.title}
                            <span className='badge'>{data.label}</span>
                        </div>
                        {data.type === 2 && ( // IS LOAN
                            <section>
                                <div className='name'>取扱金融機関</div>
                                <div className='content'>{data.jurisdiction}</div>
                            </section>
                        )}
                        <section>
                            <div className='name'>概要</div>
                            <div className='content'>{data.overview}</div>
                        </section>
                        <section>
                            <div className='name'>対象企業</div>
                            <div className='content'>{data.targetGroups}</div>
                        </section>
                        {data.type === 2 ? ( // IS LOAN
                            <>
                                <section>
                                    <div className='name'>資金使途</div>
                                    <div className='content'>{data.intendedUse}</div>
                                </section>
                                <section>
                                    <div className='name'>限度額</div>
                                    <div className='content'>{data.amount}</div>
                                </section>
                                <section>
                                    <div className='name'>返済期間</div>
                                    <div className='content'>{data.repaymentPeriod}</div>
                                </section>
                                <section>
                                    <div className='name'>利率</div>
                                    <div className='content'>{data.interestRate}</div>
                                </section>
                                <section>
                                    <div className='name'>保証人・担保等</div>
                                    <div className='content'>{data.guarantor}</div>
                                </section>
                            </>
                        ) : (
                            <>
                                <section>
                                    <div className='name'>助成額</div>
                                    <div className='content'>{data.amount}</div>
                                </section>
                                <section>
                                    <div className='name'>募集期間</div>
                                    <div className='content'>{data.applicationPeriod}</div>
                                </section>
                            </>
                        )}
                        <div className='helper-text'>
                            <div>
                                ※当ページ上で募集期間を過ぎている場合でも、追加募集が行われる可能性がございます。本公的資金の最新情報や利用要件等は、下記「外部リンク」よりご確認ください。
                            </div>
                            <div>※このページに記載の内容は、{moment(data.updatedAt).format('YYYY年MM月DD日')}現在のものです。</div>
                        </div>

                        <section className='requirements-section'>
                            <div className='requirements'>活用可能性を診断</div>
                            <br />
                            {data?.fundRequirements?.map((fundRequirements, index1) => (
                                <Fragment key={index1}>
                                    <strong className='d-block mb-2'>{fundRequirements.title}</strong>
                                    <p className='mb-4'>以下のチェックボックスの当てはまるものを選択し「診断」をクリックしてください。</p>
                                    <Form.Item className='checkbox-desc'>
                                        <Checkbox.Group className='text-start' onChange={onChange(fundRequirements)}>
                                            {fundRequirements?.requirements?.map((fund, index2) => (
                                                <Fragment key={index2}>
                                                    <Checkbox value={index1 + '_' + index2}>{fund.content}</Checkbox>
                                                    <br />
                                                </Fragment>
                                            ))}
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Fragment>
                            ))}
                            <div className='text-center'>
                                <Button htmlType='submit' className='diagnose-button'>
                                    診断
                                </Button>
                            </div>
                        </section>

                        <section className='more-details'>
                            ●詳細については、右側の 「参考ページ（外部リンク）」 からご覧ください。※外部サイトに移動します。
                            <a href={data.url} target='_blank' rel='noreferrer'>
                                <Button className='external'>参考ページ（外部リンク）</Button>
                            </a>
                        </section>
                    </Form>
                )}
            </section>
        </Highlight>
    );
};

export default FundDetails;
