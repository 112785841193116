import React, { ChangeEvent, useEffect, useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Col, Row, Input, Select, Space, Table, Pagination } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { PolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';
import { getPolicyApplications, getPolicyApplicationStatusTypes, selectPolicyApplications } from 'app/slice/policyApplicationListSlice';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { formatDate, scrollToTop, useParamsState } from 'lib/utils';
import { MeetingStatuses } from 'enums';
import { setSearchQuery } from 'app/slice/searchQuerySlice';
import classNames from 'classnames';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';

const { Column } = Table;

const WorkingRegulationMeetings = () => {
    const { Search } = Input;
    const { policyApplications, pagination } = useAppSelector(selectPolicyApplications);
    const { policyApplicationStatusTypes } = useAppSelector(selectPolicyApplications);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // Filter Function Constants
    const [keywords] = useParamsState('keyword', '');
    const [keywordChange, setKeywordChange] = useState<string>();
    const [page, setPage] = useParamsState<number>('page', 1, parseInt);
    const [selectedStatus] = useParamsState<number>('status', -1, parseInt);
    const [sort, setSort] = useState<string | undefined>('');

    const onChangeStatus = (value: number) => {
        searchParams.set('page', '1');
        searchParams.set('status', value.toString());
        setSearchParams(searchParams);
    };

    const onSearch = (value: string) => {
        searchParams.set('page', '1');
        searchParams.set('keyword', value);
        setSearchParams(searchParams);
    };

    const onChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeywordChange(event.target.value);
    };

    // Get Applications and Application Statuses
    useEffect(() => {
        dispatch(
            getPolicyApplications({
                keywords: keywords,
                page: page,
                status: selectedStatus,
                sortBy: sort,
            })
        );
        dispatch(getPolicyApplicationStatusTypes());
    }, [dispatch, keywords, navigate, page, selectedStatus, sort]);

    useEffect(() => {
        setKeywordChange(keywords);
    }, [keywords]);

    // Return scroll to top on page change only
    useEffect(() => {
        scrollToTop('smooth');
    }, [page]);

    useEffect(() => {
        dispatch(setSearchQuery('?' + searchParams.toString()));
    }, [dispatch, searchParams]);

    const getColorStatus = (status?: string) => {
        switch (status) {
            case MeetingStatuses.SCHEDULED:
                return 'status-scheduled';
            case MeetingStatuses.FINISHED:
                return 'status-finished';
            case MeetingStatuses.CANCELED:
                return 'status-canceled';
            case MeetingStatuses.PAST_DUE:
                return 'status-past-due';
            case MeetingStatuses.APPLIED:
                return 'status-applied';
            case MeetingStatuses.WAITING:
                return 'status-waiting';
            case MeetingStatuses.NOT_IMPLEMENTED:
                return 'status-not-implemented';
            default:
                return '';
        }
    };

    const renderMeetingCreatedDate = (value: ChangeEvent<HTMLInputElement>, application: PolicyApplicationDetails) => {
        const meetingCreatedDate = application?.meeting_created_date;
        const formattedMeetingCreatedDate = formatDate(meetingCreatedDate);
        return <div>{formattedMeetingCreatedDate}</div>;
    };

    const renderStatus = (value: any, application: PolicyApplicationDetails) => {
        return (
            <Space>
                <span className={`gutter-row consultation-list-card-status ${getColorStatus(application.label?.content)}`}>
                    {application.label?.content}
                </span>
            </Space>
        );
    };

    const getRowClassName = (application: PolicyApplicationDetails) => {
        const isApplicationFinished = application.label?.content === MeetingStatuses.FINISHED;
        return classNames('custom-row-class', { finished: isApplicationFinished });
    };

    const renderActionButtons = (value: any, application: PolicyApplicationDetails) => {
        return (
            <div className='action-buttons'>
                <Link to={`/dashboard/working-regulation/meetings/${application.uuid}`}>
                    <RightOutlined width='24px' height='12px' className='fs-3 blue-color-icon' />
                </Link>
            </div>
        );
    };

    const handleRowClick = (uuid: string) => {
        navigate(`/dashboard/working-regulation/meetings/${uuid}`, {
            state: { searchParams: '?' + searchParams.toString() },
        });
    };

    const handleSortChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<PolicyApplicationDetails> | SorterResult<PolicyApplicationDetails>[]
    ) => {
        if (!Array.isArray(sorter)) {
            let sortValue = '';
            if (sorter.order) {
                // Adjust this logic to set descending order by default
                if (sorter.order === 'descend') {
                    sortValue = 'desc'; // Default to 'desc' when the order is 'descend'
                } else {
                    sortValue = 'asc'; // Set to 'asc' if the order is 'ascend'
                }
            }
            setSort(sortValue);
        }
    };

    return (
        <div className='content kisoku-meetings'>
            <div className='header-container'>
                <div className='title'>面談依頼一覧</div>
                <div className='page-actions'>
                    <div className='subtitle'>累計：{pagination.total ? pagination.total : 0}件</div>
                </div>
            </div>

            <Row gutter={10}>
                <Col span={8} md={5} xl={3} className='subtitle'>
                    ステータス
                </Col>
                <Col span={16} md={19} xl={7}>
                    <Select className='page-actions-items page-actions-items-select me-2' value={selectedStatus} onChange={onChangeStatus}>
                        <Select.Option value={-1}>表示項目</Select.Option>
                        {policyApplicationStatusTypes.map((row) => (
                            <Select.Option key={row.code} value={row.code}>
                                {row.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8} md={5} xl={3} className='subtitle'>
                    文字検索
                </Col>
                <Col span={16} md={19} xl={9}>
                    <Search
                        placeholder='入力してください'
                        onSearch={onSearch}
                        value={keywordChange}
                        onChange={onChangeKeyword}
                        className='page-actions-items page-actions-items-search'
                    />
                </Col>
            </Row>

            <Table
                className='kisoku-meetings-table mt-3'
                dataSource={policyApplications}
                rowKey='branchId'
                pagination={false}
                scroll={{ x: '100%' }}
                rowClassName={getRowClassName}
                onRow={(record) => ({
                    onClick: () => {
                        handleRowClick(record.uuid);
                    },
                })}
                onChange={handleSortChange}
            >
                <Column
                    title={<span className='w-100 d-flex justify-content-center column-title'>依頼日</span>}
                    dataIndex='createdAt'
                    key='createdAt'
                    render={renderMeetingCreatedDate}
                    align='center'
                    width={140}
                />
                <Column
                    title={<span className='w-100 d-flex justify-content-center column-title'>ステータス</span>}
                    dataIndex='label'
                    key='label'
                    render={renderStatus}
                    align='center'
                    width={180}
                    className='px-1'
                />
                <Column
                    title={<span className='w-100 d-flex justify-content-center column-title'>面談対象規程</span>}
                    dataIndex='type'
                    key='type'
                    align='center'
                    width={350}
                />
                <Column
                    title={<span className='w-100 d-flex justify-content-center column-title'>面談日</span>}
                    dataIndex='schedule'
                    key='schedule'
                    sorter
                    align='center'
                    width={140}
                />

                <Column
                    className='text-center'
                    width='10%'
                    title=''
                    dataIndex='actionButtons'
                    key='actionButtons'
                    render={renderActionButtons}
                />
            </Table>

            <div className='text-center'>
                {pagination?.total > 0 && (
                    <Pagination
                        className='d-inline float-center'
                        defaultPageSize={pagination.perPage}
                        current={page}
                        total={pagination.total}
                        onChange={setPage}
                        showSizeChanger={false}
                    />
                )}
            </div>
        </div>
    );
};

export default WorkingRegulationMeetings;
