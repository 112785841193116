import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Form, Input, Modal, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AuthRequest } from 'app/slice/authSlice';
import { login, resetLogin, selectLogin } from 'app/slice/loginSlice';
import { Rule } from 'antd/lib/form';
import { scrollToTop } from 'lib/utils';
import { ExclamationCircleFilled } from '@ant-design/icons';

type Props = {
    isVisible: boolean,
    onClose: () => void,
    onOpenForgotPasswordModal: () => void
}

const emailAddressRule: Rule[] = [
    { required: true, whitespace: true, message: <><ExclamationCircleFilled/> 正しい入力形式で入力してください</> },
    { type: 'email', message: '有効なメールアドレスを入力してください' },
];

const passwordRule: Rule[] = [
    { required: true, whitespace: true, message: <><ExclamationCircleFilled/> パスワードが一致しません</> },
];

const LoginModal = ((props: Props) => {
    const { isVisible, onClose, onOpenForgotPasswordModal } = props;
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { success, loading, message } = useAppSelector(selectLogin);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const onFormSubmit = (values: AuthRequest) => {
        const { email, password } = values;
        dispatch(login({ email, password }));
    };

    const onClickForgotPassword = () => {
        onClose();
        onOpenForgotPasswordModal();
    };

    const redirectLink = useMemo(() => {
        if (location.pathname.includes('/kisoku')) {
            return '/kisoku/terms-of-service';
        }
        return '/kouteki/terms-of-service';
    }, [location.pathname]);

    useEffect(() => {
        if (success) {
            form.resetFields();
            onClose();
            dispatch(resetLogin());

            if (location.pathname.startsWith('/kisoku') || location.pathname.startsWith('/kouteki')) {
                scrollToTop();
            } else {
                navigate('/dashboard/management');
            }
        }
        
    }, [dispatch, form, success, navigate, onClose, location.pathname]);

    const handleFormChange = () => {
        // Disable button if any field is empty
        const { email, password } = form.getFieldsValue();
        setIsSubmitDisabled(!email || !password);
    };

    return (
        <Modal className='login-modal' visible={isVisible} onCancel={onClose} footer={null}>
            <div className='title'>ログイン</div>
            <div className='subtitle'>
                専門家への相談サービスをご利用になるにはログインが必要です。<br />
                ご登録がお済みでないお客さまは、大同生命の営業担当者にご連絡ください。<br />
                <br />
                <span className="lock-warning">※ログイン失敗回数が一定数を超えると、アカウントがロックされますので、ご注意ください。</span>
               
            </div>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFormSubmit}
                autoComplete='off'
                requiredMark={false}
                onValuesChange={handleFormChange}
            >
                {
                    (!loading && !success && message) &&
                    <Alert className='mb-3' message={message} type='error' showIcon closable />
                }
                <Form.Item
                    name='email'
                    label={
                        <>メールアドレス<span className='required-text ml-2'>(必須)</span> </>
                    }
                    rules={emailAddressRule}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='password'
                    label={
                        <>パスワード<span className='required-text ml-2'>(必須)</span> </>
                    }
                    rules={passwordRule}
                >
                    <Input.Password />
                </Form.Item>
                <div className='link text-center text-decoration-underline' onClick={onClickForgotPassword}>パスワードをお忘れの方</div>
                <Button type='primary' htmlType='submit' className='w-100 submit-button' style={{ borderRadius: '10px' }} disabled={loading || isSubmitDisabled}>
                    {loading ? <Spin size='small' /> : 'ログイン'}
                </Button>
            </Form>
            <div className='helper-text'>
                本サイトをご利用いただくことにより、利用者は、<Link to={redirectLink} target='_blank'>利用規約</Link>に同意したものとみなされます。<br />
                ご利用に際しては、必ず利用規約の規定をご精読ください。<br />
                なお、本利用規約は予告なく変更されることがあります。
            </div>
        </Modal>
    );
});

export default LoginModal;
