import { ApplicationDetails } from 'app/slice/applicationDetailsSlice';
import { Space, Table } from 'antd';
import { selectApplications } from 'app/slice/applicationListSlice';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { ChangeEvent } from 'react';
import { ConsultationStatuses } from 'enums';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { formatDate } from 'lib/utils';

const { Column } = Table;

type Props = {
    setSort: (value: string | undefined) => void;
};

const ConsultationListCardUpdateVersion = (props: Props) => {
    const { setSort } = props;
    const { applications } = useAppSelector(selectApplications);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const getColorStatus = (status?: string) => {
        switch (status) {
            case ConsultationStatuses.SCHEDULED:
                return 'status-scheduled';
            case ConsultationStatuses.SELECTING_SPECIALIST:
                return 'status-selecting-specialist';
            case ConsultationStatuses.MATCHING:
                return 'status-matching';
            case ConsultationStatuses.NO_MATCH:
                return 'status-no-match';
            case ConsultationStatuses.CANCELED:
                return 'status-canceled';
            case ConsultationStatuses.PAST_DUE:
                return 'status-past-due';
            case ConsultationStatuses.FINISHED:
                return 'status-finished';
            case ConsultationStatuses.REQUESTING:
                return 'status-requesting';
            case ConsultationStatuses.WAITING_CONF:
                return 'status-waiting-conf';
            case ConsultationStatuses.SKIPPED:
                return 'status-skipped';
            default:
                return '';
        }
    };

    const renderMeetingCreatedDate = (value: ChangeEvent<HTMLInputElement>, application: ApplicationDetails) => {
        const meetingCreatedDate = application?.meeting_created_date;
        const formattedMeetingCreatedDate = formatDate(meetingCreatedDate);
        return <div>{formattedMeetingCreatedDate}</div>;
    };

    const renderStatus = (value: ChangeEvent<HTMLInputElement>, application: ApplicationDetails) => {
        return (
            <Space>
                <span className={`gutter-row consultation-list-status ${getColorStatus(application?.label?.content)}`}>
                    {application.label?.content}
                </span>
            </Space>
        );
    };

    const renderDetails = () => {
        return (
            <div>
                <RightOutlined className='fs-3 blue-color-icon' />
            </div>
        );
    };

    const renderGrantTitle = (value: ChangeEvent<HTMLInputElement>, application: ApplicationDetails) => {
        return (
            <div className='consultation-list-card-title'>
                {application?.funds?.[0].title}
                {application?.funds?.length > 1 ? '、他' : ''}
            </div>
        );
    };

    const rowLink = (application: ApplicationDetails) => {
        return {
            onClick: () => {
                navigate(`/dashboard/kouteki/consultation/${application?.uuid}/details`, {
                    state: { searchParams: '?' + searchParams.toString() }
                });
            },
        };
    };

    const handleSortChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<ApplicationDetails> | SorterResult<ApplicationDetails>[]
    ) => {
        if (!Array.isArray(sorter)) {
            let sortValue = '';
            if (sorter.order) {
                // Adjust this logic to set descending order by default
                if (sorter.order === 'descend') {
                    sortValue = 'desc'; // Default to 'desc' when the order is 'descend'
                } else {
                    sortValue = 'asc'; // Set to 'asc' if the order is 'ascend'
                }
            }
            setSort(sortValue);
        }
    };

    return (
        <Table
            className='consultation-list-table mt-3'
            dataSource={applications}
            rowKey='uuid'
            scroll={{ x: '100%' }}
            pagination={false}
            rowClassName={'custom-row-class'}
            onRow={rowLink}
            onChange={handleSortChange}
        >
            <Column
                title={<span className='w-100 d-flex justify-content-center column-title'>依頼日</span>}
                dataIndex='meeting_created_date'
                key='meeting_created_date'
                render={renderMeetingCreatedDate}
                align='center'
                width={140}
            />
            <Column
                title={<span className='w-100 d-flex justify-content-center column-title'>ステータス</span>}
                dataIndex='label'
                key='label'
                render={renderStatus}
                align='center'
                width={180}
                className='px-1'
            />
            <Column
                title={<span className='w-100 d-flex justify-content-center column-title'>公的資金名称</span>}
                dataIndex='funds'
                key='funds'
                render={renderGrantTitle}
                align='center'
                width={350}
            />
            <Column
                title={<span className='w-100 d-flex justify-content-center column-title'>面談日</span>}
                dataIndex='schedule'
                key='schedule'
                align='center'
                sorter
                width={140}
            />
            <Column title='' dataIndex='details' key='uuid' render={renderDetails} width={50} />
        </Table>
    );
};

export default ConsultationListCardUpdateVersion;
