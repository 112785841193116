import React, { useEffect } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { Application } from 'app/slice/companySlice';

/**
 * Props for Breadcrumb
 */
const breadcrumbs: Breadcrumb[] = [
    {
        name: 'トップページ',
        url: '/kouteki',
    },
    {
        name: '検索',
        url: '/kouteki/funds/search' + localStorage.getItem('searchPath'),
    },
    {
        name: '活用可能性の診断結果',
        url: '/kouteki/funds/search' + localStorage.getItem('searchPath'),
    },
    {
        name: '面談申し込み',
        url: '/kouteki/funds/search' + localStorage.getItem('searchPath'),
    },
];

export type FormType = {
    remarks: string;
    daido_life: number;
};

const DiagnoseResultScheduleFinished = () => {
    const { application } = (useLocation()?.state || {}) as { application: Application };
    const navigate = useNavigate();

    // Redirect if application does not have value
    useEffect(() => {
        if (!application) {
            navigate('/kouteki/funds/search');
        }
    }, [application, navigate]);

    // Format full selected schedule for summary
    const formatSchedule = () => {
        const schedule = new Date(application?.meeting?.schedule);

        const year = schedule.getFullYear();
        const month = (schedule.getMonth() + 1).toString().padStart(2, '0');
        const day = schedule.getDate().toString().padStart(2, '0');
        const hours = schedule.getHours().toString().padStart(2, '0');
        const minutes = schedule.getMinutes().toString().padStart(2, '0');

        return `${year}年${month}月${day}日 ${hours}:${minutes}~${hours}:${(parseInt(minutes) + 30).toString().padStart(2, '0')}`;
    };

    const goToDashboardNotifications = () => {
        navigate('/dashboard/notifications');
    };

    return (
        <>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs} />
            </section>
            <section className='content diagnose-schedule-finished-container'>
                <div className='title'>オンライン面談の予約が完了しました</div>
                <div className='subtitle'>下記の日程でオンライン面談予約をお受けしました。</div>
                <div className='schedule'>{formatSchedule()}</div>
                <div>面談URLはマイページの「公的支援メニュー」内、「面談依頼一覧」からご確認ください。</div>
                <div className='schedule-btn-section'>
                    <Button type='primary' onClick={goToDashboardNotifications} htmlType='submit'>
                        マイページへ移動
                    </Button>
                </div>
            </section>
        </>
    );
};

export default DiagnoseResultScheduleFinished;
