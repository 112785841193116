import React, { useState } from 'react';
import instructionStep1 from 'assets/images/instruction_step_1.png';
import instructionStep2 from 'assets/images/instruction_step_2.png';
import instructionStep3 from 'assets/images/instruction_step_3.png';
import { selectFundApplication } from 'app/slice/fundApplicationSlice';
import { Button, Checkbox } from 'antd';
import { useAppSelector } from 'app/hooks';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { FundsLocationState } from 'pages/kouteki/DiagnoseResult';
import { selectMeeting } from 'app/slice/meetingSlice';

type Props = {
    setIsShowInstruction: (value: boolean) => void;
    onCloseScheduleLimitModal: (value: boolean) => void;
    funds: FundsLocationState['funds'];
};

const InstructionDetail = (props: Props) => {
    const [isChecked, setChecked] = useState(false);
    const { loading } = useAppSelector(selectFundApplication);
    const { fundList } = useAppSelector(selectMeeting);
    const navigate = useNavigate();
    const onChangeCheck = () => {
        setChecked(!isChecked);
    };

    const handleBackClick = () => {
        props.setIsShowInstruction(false);
        props.onCloseScheduleLimitModal(true);
    };

    const onSubmit = () => {
        let fundData;
        if (isChecked && fundList.length > 0) {
            // Do not include 基本確認項目 in the funds array and filter funds based on checked funds on multiple diagnosis page that allows consultation.
            fundData = props.funds.filter((item) => item.title !== '基本確認項目' && fundList.includes(item.code));
        } else {
            // Do not include 基本確認項目 in the funds array (applicable for single diagnosis).
            fundData = props.funds.filter((item) => item.title !== '基本確認項目');
        }
        navigate('/kouteki/funds/diagnose/schedule', { state: { funds: fundData } });
    };

    return (
        <>
            <div className='title'>『専門家相談窓口』のご案内</div>
            <div className='subtitle'>
                お客さまが診断された公的資金について、またはそれに伴う経営課題について、本サービスの提供元である中小企業福祉事業団の会員社会保険労務士、または会員中小企業診断士が、お客さまのご相談にオンラインでご対応いたします。
            </div>
            <br />
            <div className='subtitle-list'>※オンライン相談のみのご対応となり、申請手続きはできかねます。</div>
            <div className='detail-title-instruction'>ご利用方法</div>
            <div className='instruction-container'>
                <div className='instruction-card'>
                    <div className='instruction-image'>
                        <img src={instructionStep1} alt='logo' />
                    </div>
                    <div className='instruction-title'>
                        <span>1</span>面談予約フォームの入力
                    </div>
                    <div className='instruction-content'>面談予約フォームから、面談実施希望日時を選択し、必要情報を入力します。</div>
                    <div className='instruction-list'>※面談予約可能日は土日祝日を除きます。</div>
                </div>

                <div className='instruction-card'>
                    <div className='instruction-image'>
                        <img src={instructionStep2} alt='logo' />
                    </div>
                    <div className='instruction-title'>
                        <span>2</span>面談予約の確定
                    </div>
                    <div className='instruction-content'>
                        面談予約フォームに必要情報入力後、確認画面にて「面談を予約する」を選択すると面談予約が確定します。 <br />
                        面談予約の詳細および当時のWeb面談URL（Zoom）は「管理画面（マイページ）」からご確認いただけます。
                    </div>
                </div>

                <div className='instruction-card'>
                    <div className='instruction-image'>
                        <img src={instructionStep3} alt='logo' />
                    </div>
                    <div className='instruction-title'>
                        <span>3</span>予約当日:オンライン面談の実施
                    </div>
                    <div className='instruction-content'>
                        予約日時を迎えましたら、マイページのWeb面談URLからZoomへアクセスしてください。
                    </div>
                    <div className='instruction-list'>※予約時間5分前から入室可能です。</div>
                    <div className='instruction-content'>ご予約日時の変更やキャンセルなどは、マイページから行えます。</div>
                </div>
            </div>
            <div className='instruction-notes'>
                <div>【ご確認事項】</div>
                <div>
                    ・<span>本サービスのオンライン面談には「Zoom（ズーム）」を使用します。</span>
                    Zoomの使用に際し、ご不明点等ございましたら、大同生命営業担当者までお気軽にお問い合わせください。
                    <br />
                    ・<span>面談の予約上限は、２件までとなります。</span>２件ご予約されている状態で、新規の相談を希望される場合は、予約済みの面談が終了してからご利用ください。
                    <br />
                    ・面談可能日時の枠は先約制です。ご希望の日時に空きが無い場合は、恐れ入りますが、カレンダーの更新をお待ちください。
                </div>
            </div>
            <div className='instruction-btn-group w-100'>
                <div className='mt-2 instruction-btn-section mb-4'>
                    <Button type='default' onClick={handleBackClick}>
                        <LeftOutlined className='arrow-icon left-outline' />
                        <span>戻る</span>
                    </Button>
                </div>

                <div className='instruction-btn-section w-100'>
                    <div className='text-center mb-3 fw-bold checkbox-form'>
                        <Checkbox checked={isChecked} onChange={onChangeCheck}>
                            上記内容を確認のうえ、『中企団オンライン相談窓口』を利用します
                        </Checkbox>
                    </div>
                    <div className='btn-group text-center'>
                        <Button type='primary' onClick={onSubmit} disabled={!isChecked} loading={loading}>
                            相談窓口予約フォームへ
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InstructionDetail;
