import React, { Fragment, useState } from 'react';
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Row, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { Meeting, Topic, Type } from 'app/slice/policySlice';
import RequestMeetingConfirmationModal from 'components/dashboard/working_regulation/RequestMeetingConfirmationModal';
import { LeftOutlined } from '@ant-design/icons';

export type MeetingRequest = {
    meeting: Meeting;
    topics: Topic[];
    type: number;
    remarks: string;
};

type Props = {
    topics: Topic[];
    types: Type[];
    isVisible: boolean;
    schedule: string;
    scheduleLabel: string;
    onOpenScheduleMeetingModal: () => void;
    onOpenRequestMeetingModal: () => void;
    onClose: () => void;
};

const daidoManagerRules: Rule[] = [
    {
        required: true,
        message: '大同生命営業担当者の同席をご選択ください。',
    },
];

const consultationContentRules: Rule[] = [
    {
        required: true,
        message: '相談内容をご入力ください。',
    },
];

const interviewTypeRules: Rule[] = [
    {
        required: true,
        message: '面談を行う規程をご選択ください。',
    },
];

const consultationContentLabel = () => {
    return (
        <>
            <span className='note-required'>※面談を円滑に進めるため、 ご相談事項を事前にご記入ください</span>
        </>
    );
};

const { Option } = Select;
const { TextArea } = Input;

const RequestMeetingModal = (props: Props) => {
    const { topics, types, isVisible, schedule, scheduleLabel, onOpenScheduleMeetingModal, onOpenRequestMeetingModal, onClose } = props;
    const { Item } = Form;
    const { Group } = Radio;
    const [data, setData] = useState<MeetingRequest>();
    const [form] = Form.useForm();
    const [isRequestMeetingConfirmationVisible, setIsRequestMeetingConfirmationVisible] = useState(false);
    const onCloseRequestMeetingConfirmationModal = () => setIsRequestMeetingConfirmationVisible(false);
    const onOpenRequestMeetingConfirmationModal = () => setIsRequestMeetingConfirmationVisible(true);

    const onFinish = () => {
        const formValues = form.getFieldsValue();
        const formData: MeetingRequest = {
            meeting: {
                schedule: schedule,
                daido_joins: formValues.daido_joins,
                calendar_type: 3, // Always 3 since it is POLICY
            },
            topics: formValues.topics || [],
            type: formValues.type,
            remarks: formValues.remarks,
        };

        setData(formData);
        form.resetFields();
        onClose();
        onOpenRequestMeetingConfirmationModal();
    };

    const onCloseRequestMeetingModal = () => {
        form.resetFields();
        onClose();
    };

    const onBackToScheduleMeetingModal = () => {
        onCloseRequestMeetingModal();
        onOpenScheduleMeetingModal();
    };

    return (
        <>
            <Modal className='request-meeting-modal' visible={isVisible} onCancel={onClose} footer={null}>
                <div className='title'>オンライン面談依頼</div>
                <div className='subtitle'>ページ（ 2 / 2 ）</div>
                <div>
                    <Row>
                        <p className='note'>
                            ②面談を行う規程<span className='note-required'>(必須)</span>
                        </p>
                    </Row>
                    <Divider className='note-divider' />
                </div>
                <Form layout='vertical' onFinish={onFinish} form={form}>
                    <div className='kisoku-title-dropdown'>
                        <Item name='type' rules={interviewTypeRules}>
                            <Select placeholder='ご選択ください。'>
                                {types?.map((type) => (
                                    <Option key={type.code} value={type.code}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                        </Item>
                    </div>

                    <div>
                        <Row>
                            <p className='note'>
                                ③特にご相談したいことをチェックしてください。<span className='gray-note-required'>(任意/複数選択可)</span>
                            </p>
                        </Row>
                        <Divider className='note-divider' />
                    </div>

                    <Item name='topics'>
                        <Checkbox.Group>
                            {topics?.map((topic, key) => (
                                <Fragment key={key}>
                                    <Checkbox value={topic}>{topic.name}</Checkbox>
                                    <br />
                                </Fragment>
                            ))}
                        </Checkbox.Group>
                    </Item>

                    <div>
                        <Row>
                            <p className='note'>
                                相談内容詳細<span className='note-required'>(必須)</span>
                            </p>
                        </Row>
                        <Divider className='note-divider' />
                    </div>

                    <Item name='remarks' label={consultationContentLabel()} rules={consultationContentRules}>
                        <TextArea
                            className='text-area-placeholder'
                            placeholder='例）服務規律について、 現在の内容で問題が無いか相談したい'
                            rows={4}
                        />
                    </Item>

                    <div>
                        <Row>
                            <p className='note'>
                                大同生命営業担当者<span className='note-required'>(必須)</span>
                            </p>
                        </Row>
                        <Divider className='note-divider' />
                    </div>

                    <Item className='daido-radio' name='daido_joins' rules={daidoManagerRules}>
                        <Group>
                            <Radio value={true}>同席を希望します</Radio>
                            <Radio value={false}>同席を希望しません</Radio>
                        </Group>
                    </Item>

                    <div className='helper-text mb-4 daido-note'>
                        <p className='daido-first-note'>
                            ※大同生命の営業担当者の同席の有無については、事前に営業担当者にご相談の上、ご選択ください。
                        </p>
                        <p>※都合が合わない場合、同席できないこともありますので予めご了承ください。</p>
                    </div>

                    <Item className='action-buttons'>
                        <Row gutter={[10, 10]}>
                            <Col span={12}>
                                <Button className='btn-close' onClick={onBackToScheduleMeetingModal}>
                                    <h3 className='btn-close-text'>
                                        <LeftOutlined className='btn-icon' />
                                        戻る
                                    </h3>
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button className='btn-submit' htmlType='submit'>
                                    <h3 className='btn-submit-text'>確認画面に進む</h3>
                                </Button>
                            </Col>
                        </Row>
                    </Item>
                </Form>
            </Modal>

            <RequestMeetingConfirmationModal
                isVisible={isRequestMeetingConfirmationVisible}
                onClose={onCloseRequestMeetingConfirmationModal}
                request={data}
                types={types}
                scheduleLabel={scheduleLabel}
                onOpenRequestMeetingModal={onOpenRequestMeetingModal}
            />
        </>
    );
};

export default RequestMeetingModal;
