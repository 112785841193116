import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { getApplicationDetails } from 'app/slice/applicationDetailsSlice';
import { getPolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';
import { Params, useParams } from 'react-router-dom';

type Props = {
    isVisible: boolean;
    title: string;
    header: string;
    subHeader: string;
    newScheduleLabel: string;
    fundType?: number;
    onClose: () => void;
};
const NewScheduleModal = (props: Props) => {
    const params = useParams<Params>();
    const dispatch = useAppDispatch();
    const { isVisible, title, header, subHeader, newScheduleLabel, fundType, onClose } = props;

    const onCloseNewScheduleModal = () => {
        if (params.uuid) {
            if (fundType === 4 || fundType === 3) {
                //Automatically update kouteki meeting detail page schedule date and time.
                dispatch(getApplicationDetails(params.uuid));
            } else {
                //Automatically update kisoku meeting detail page schedule date and time.
                dispatch(getPolicyApplicationDetails(params.uuid));
            }
        }
        onClose();
    };

    return (
        <>
            <Modal className={'appointment-changed-modal'} visible={isVisible} footer={null} onCancel={onClose} maskClosable={false}>
                <div className='title'>{title}</div>

                <div className='subtitle'>
                    <p className='subtitle-top'>{header}</p>
                    <p className='subtitle-bottom'>{subHeader}</p>
                </div>

                <div className='appointment-changed-div'>
                    <Row className='appointment-changed-row'>
                        <Col span={8} className='appointment-changed-label'>
                            変更後
                        </Col>

                        <Col span={16} className='appointment-changed-date'>
                            {newScheduleLabel}
                        </Col>
                    </Row>
                </div>

                <div className='action-button'>
                    <Button className='btn-close' onClick={onCloseNewScheduleModal} htmlType='button'>
                        <h3 className='btn-close-text'>
                            <CloseOutlined className='btn-icon' />
                            閉じる
                        </h3>
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default NewScheduleModal;
