import { Fragment } from 'react';
import { Col, Form, Row } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { FundsLocationState } from 'pages/kouteki/DiagnoseResult';

const DiagnoseNotAllowConsultation = () => {
    const [form] = Form.useForm();
    const { funds } = useLocation().state as FundsLocationState;
    const basicRequirementsChecked = funds.find((code) => code.title === '基本確認項目')?.result ?? true;
    const fundsWithoutBasicRequirements = funds.filter((fund) => fund.code);
    const isShowNote = fundsWithoutBasicRequirements.some((fund) => fund.result);

    return (
        <>
            {/* This is where mapping of results happened. */}
            <Form form={form} layout='vertical' autoComplete='off'>
                {funds
                    .filter((fund) => fund.title !== '基本確認項目')
                    .map((fund, key) =>
                        basicRequirementsChecked && fund.result ? (
                            <Fragment key={key}>
                                {isShowNote && !fund.allowConsultation ? (
                                    <div>
                                        <Row className='inner-content not-allow'>
                                            <Col xs={24} md={24}>
                                                <Row gutter={16} className='align-items-center'>
                                                    <Col md={18} className='grant-name'>
                                                        <a
                                                            href={`/kouteki/funds/${fund.code}/details`}
                                                            className='grant-details-page'
                                                            target='_blank'
                                                            rel='noreferrer'
                                                        >
                                                            {fund.title}
                                                        </a>
                                                    </Col>
                                                    <Col md={6} className='external-link'>
                                                        <a href={fund.url} className='link-kanri' target='_blank' rel='noreferrer'>
                                                            参考ページ（外部リンク） <ExportOutlined />
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null}
                            </Fragment>
                        ) : null
                    )}
            </Form>
        </>
    );
};

export default DiagnoseNotAllowConsultation;
