import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Modal, Row } from 'antd';
import { Topic, Type } from 'app/slice/policySlice';
import { CloseOutlined } from '@ant-design/icons';
import RequestMeetingModal from './RequestMeetingModal';
import CalendarSchedule from 'components/common/CalendarSchedule';
import { formatDateWithWeekday } from 'lib/utils';

type Props = {
    topics: Topic[];
    types: Type[];
    isVisible: boolean;
    onOpenScheduleMeetingModal: () => void;
    onClose: () => void;
};

const ScheduleMeetingModal = (props: Props) => {
    const { topics, types, isVisible, onOpenScheduleMeetingModal, onClose } = props;
    const [isRequestMeetingVisible, setIsRequestMeetingVisible] = useState(false);
    const [isDisabledConfirmationButton, setDisabledConfirmationButton] = useState(false);
    const onCloseRequestMeetingModal = () => setIsRequestMeetingVisible(false);

    const [scheduleDate, setScheduleDate] = useState<string>('--');
    const [scheduleTime, setScheduleTime] = useState<string>('--');

    const formattedDate = scheduleDate.replace('年', '-').replace('月', '-').replace('日', '');
    const formattedDateWithWeekday = formatDateWithWeekday(formattedDate);
    const [startTime] = scheduleTime.split('~');

    const formattedLabelSchedule = formattedDateWithWeekday + ' ' + scheduleTime;
    const formattedSchedule = `${formattedDate} ${startTime}:00`;

    //Disable confirmation button if no schedule time selected.
    useEffect(() => {
        if (scheduleTime === '--') {
            setDisabledConfirmationButton(true);
        } else {
            setDisabledConfirmationButton(false);
        }
    }, [scheduleTime, setScheduleDate, setScheduleTime, scheduleDate]);

    const onOpenRequestMeetingModal = () => {
        onClose();
        setIsRequestMeetingVisible(true);
    };

    const onScheduleMeetingModalClose = () => {
        onClose();
    };

    return (
        <>
            <Modal className='schedule-meeting-modal' visible={isVisible} onCancel={onClose} footer={null} centered>
                <div className='title'>オンライン面談依頼</div>
                <div className='subtitle'>ページ（ 1/ 2 ）</div>
                <div>
                    <Row>
                        <p className='note'>
                            ①ご希望の日時を選択してください <span className='note-required'> (必須) </span>
                        </p>
                    </Row>
                    <Divider className='note-divider' />
                </div>

                <div className='schedule-calendar'>
                    <Row justify='start' className='mb-3' gutter={[16, 0]}>
                        <Col span={24} md={24}>
                            <div>
                                <CalendarSchedule
                                    setScheduleDate={setScheduleDate}
                                    setScheduleTime={setScheduleTime}
                                    scheduleDate={scheduleDate}
                                    scheduleTime={scheduleTime}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row>
                        <Col md={9}>
                            <Button className='btn-close' onClick={onScheduleMeetingModalClose}>
                                <h3 className='btn-close-text'>
                                    <CloseOutlined className='btn-icon' />
                                    閉じる
                                </h3>
                            </Button>
                        </Col>
                        <Col md={9}>
                            <Button disabled={isDisabledConfirmationButton} className='btn-next' onClick={onOpenRequestMeetingModal}>
                                <h3 className='btn-next-text'>次の入力項目へ</h3>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <RequestMeetingModal
                topics={topics}
                types={types}
                isVisible={isRequestMeetingVisible}
                schedule={formattedSchedule}
                scheduleLabel={formattedLabelSchedule}
                onClose={onCloseRequestMeetingModal}
                onOpenScheduleMeetingModal={onOpenScheduleMeetingModal}
                onOpenRequestMeetingModal={onOpenRequestMeetingModal}
            />
        </>
    );
};

export default ScheduleMeetingModal;
