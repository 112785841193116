import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import { changeMeetingScheduleAPI, changePasswordUser, unsubcribeUser, checkScheduleUser, createMeetingApplicationApi, getCalendarScheduleApi } from 'api/company';
import { RootState } from 'app/store';
import { CalendarType } from 'enums';

export type COMPANY_STATUS = 'success' | 'failed' | 'pending';

type CompanyState = {
    loading: boolean,
    success: boolean,
    type: string,
    updateSuccess: boolean,
    status: COMPANY_STATUS,
    message: string,
    canApply: boolean,
    calendar: CalendarSchedule
    application: Application,
    changeMeetingSchedule: {
        loading: boolean,
        success: boolean | null,
    }
}

export type Application = {
    uuid: string,
    meeting: Meeting
}

type Meeting = {
    schedule: string,
    joinUrl: string,
    daidoJoins: number
}

type CalendarSchedule = {
    availableSchedules: AvailableSchedules[];
    holidays: {
        start: string,
        end: string,
    }[];
    selectableFrom: string;
    selectableUntil: string;
};

type AvailableSchedules = {
    date: string,
    time: string[]
}

type FundsRequest = {
    code: string,
    remarks: string
}

type MeetingRequest = {
    schedule: string,
    daido_joins: number,
    calendar_type: number
}

export type GetCalendarScheduleRequest = {
    month: string,
    type: number,
    taken?: number
}

export type ChangePasswordRequest = {
    current_password: string,
    new_password: string,
    new_password_confirmation: string
}


export type ChangeMeetingScheduleRequest = {
    new_schedule: string,
    type: CalendarType | null,
    uuid: string
}

export type CreateMeetingApplicationRequest = {
    funds: FundsRequest[],
    meeting: MeetingRequest
}

export const CREATE_MEETING_APPLICATION = 'CREATE_MEETING_APPLICATION';
export const GET_CALENDAR_SCHEDULES = 'GET_CALENDAR_SCHEDULES';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const CHECK_SCHEDULE = 'CHECK_SCHEDULE';

/**
 * changePassword user
 **/
export const changePassword = createAsyncThunk(
    'company/user/password',
    async (data: ChangePasswordRequest, { getState, rejectWithValue }) => {
        const { current_password, new_password, new_password_confirmation } = data;
        // @ts-ignore
        const { loading } = getState().company;
        if (!loading) {
            return;
        }
        try {
            const response = await changePasswordUser(current_password, new_password, new_password_confirmation);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * unsubscribe user
 **/
export const unsubscribe = createAsyncThunk(
    'company/user',
    async (_, { getState, rejectWithValue }) => {
        // @ts-ignore
        const { loading } = getState().company;
        if (!loading) {
            return;
        }
        try {
            const response = await unsubcribeUser();
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * check if user has schedule
 **/
export const checkSchedule = createAsyncThunk(
    'company/user/schedule',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await checkScheduleUser();
            if (response.data.success === true) {
                dispatch(setCheckSchedule(response.data));
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * change meeting schedule
 **/
export const changeMeetingSchedule = createAsyncThunk(
    'company/meeting/application/change',
    async (data: ChangeMeetingScheduleRequest, { rejectWithValue }) => {
        try {
            const response = await changeMeetingScheduleAPI(data);
            if (response.data.success === true) {
                return true;
            }
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);


/**
 * get all holidays and schedules for calendar.
 **/
export const getCalendarSchedule = createAsyncThunk(
    'company/user/schedule/kouteki',
    async (data: GetCalendarScheduleRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getCalendarScheduleApi(data);
            if (response.data.success === true) {
                dispatch(setCalendarSchedule(response.data));
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

export const createMeetingApplication = createAsyncThunk(
    'funds/application',
    async (data: CreateMeetingApplicationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await createMeetingApplicationApi(data);

            if (response.data.success === true) {
                dispatch(setApplicationResult(response.data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);


const initialState: CompanyState = {
    loading: false,
    success: false,
    status: 'pending',
    type: '',
    message: '',
    canApply: false,
    updateSuccess: false,
    calendar: {
        availableSchedules: [] as AvailableSchedules[],
        holidays: [],
        selectableFrom: '',
        selectableUntil: '',
    },
    application: {
        uuid: '',
        meeting: {
            schedule: '',
            joinUrl: '',
            daidoJoins: 0
        }
    },
    changeMeetingSchedule: {
        loading: false,
        success: null
    }
};

export const companySlice = createSlice({
    name: 'company',
    initialState: initialState,
    reducers: {
        reset: (state: CompanyState) => {
            state.success = false;
            state.loading = false;
            state.status = 'pending';
            state.message = '';
        },
        setCheckSchedule: (state, { payload }) => {
            state.canApply = payload.data.can_apply;
        },
        setCalendarSchedule: (state, { payload }) => {
            state.calendar.availableSchedules = payload.data.available_schedules;
            state.calendar.holidays = payload.data.holidays;
            state.calendar.selectableFrom = payload.data.selectable_from;
            state.calendar.selectableUntil = payload.data.selectable_until;
        },
        setApplicationResult: (state, { payload }) => {
            state.application.uuid = payload.data.uuid;
            state.application.meeting.schedule = payload.data.meeting.schedule;
            state.application.meeting.joinUrl = payload.data.meeting.join_url;
            state.application.meeting.daidoJoins = payload.data.meeting.daido_joins;
        },
        resetChangeMeetingSchedule: (state: CompanyState) => {
            state.changeMeetingSchedule.loading = false;
            state.changeMeetingSchedule.success = null;
        },
        resetUpdateSuccess: (state) => {
            state.updateSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase((unsubscribe.pending), (state) => {
            state.type = UNSUBSCRIBE;
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase((unsubscribe.fulfilled), (state) => {
            state.type = UNSUBSCRIBE;
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase((unsubscribe.rejected), (state) => {
            state.type = UNSUBSCRIBE;
            state.loading = false;
            state.status = 'failed';
        });
        builder.addCase((changePassword.pending), (state) => {
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase((changePassword.fulfilled), (state) => {
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase((changePassword.rejected), (state) => {
            state.loading = false;
            state.status = 'failed';
        });
        builder.addCase((checkSchedule.pending), (state) => {
            state.type = CHECK_SCHEDULE;
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase((checkSchedule.fulfilled), (state) => {
            state.type = CHECK_SCHEDULE;
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase((checkSchedule.rejected), (state) => {
            state.type = CHECK_SCHEDULE;
            state.loading = false;
            state.status = 'failed';
        });
        builder.addCase((getCalendarSchedule.pending), (state) => {
            state.type = GET_CALENDAR_SCHEDULES;
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase((getCalendarSchedule.fulfilled), (state) => {
            state.type = GET_CALENDAR_SCHEDULES;
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase((getCalendarSchedule.rejected), (state) => {
            state.type = GET_CALENDAR_SCHEDULES;
            state.loading = false;
            state.status = 'failed';
        });
        builder.addCase((changeMeetingSchedule.pending), (state) => {
            state.changeMeetingSchedule.loading = true;
            state.changeMeetingSchedule.success = null
        });
        builder.addCase((changeMeetingSchedule.fulfilled), (state) => {
            state.changeMeetingSchedule.loading = false;
            state.changeMeetingSchedule.success = true;
        });
        builder.addCase((changeMeetingSchedule.rejected), (state) => {
            state.changeMeetingSchedule.loading = false;
            state.changeMeetingSchedule.success = true;
        });
        builder.addCase((createMeetingApplication.pending), (state) => {
            state.type = CREATE_MEETING_APPLICATION;
            state.loading = true;
            state.status = 'pending';
            state.updateSuccess = false;
        });
        builder.addCase((createMeetingApplication.fulfilled), (state) => {
            state.type = CREATE_MEETING_APPLICATION;
            state.loading = false;
            state.status = 'success';
            state.success = true;
            state.updateSuccess = true;
        });
        builder.addCase((createMeetingApplication.rejected), (state) => {
            state.type = CREATE_MEETING_APPLICATION;
            state.loading = false;
            state.status = 'failed';
            state.updateSuccess = false;
        });
    },
});
export const selectCompany = (state: RootState) => state.company;
export const { reset, setCheckSchedule, setCalendarSchedule, setApplicationResult, resetChangeMeetingSchedule, resetUpdateSuccess } = companySlice.actions;
