import React, { useEffect } from 'react';
import { fetchServices, selectServices } from 'app/slice/serviceSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Skeleton } from 'antd';

const Services = () => {
    const dispatch = useAppDispatch();
    const { data, loading } = useAppSelector(selectServices);

    useEffect(() => {
        dispatch(fetchServices());
    }, [dispatch]);

    return (
        <div className='content dashboard-calendar'>
            <div className='title'>サービスの使い方</div>
            {loading ? (
                <Skeleton active />
            ) : data.map((service, key) => (
                <a key={key} href={service.url} target='_blank' rel='noreferrer' className='d-block mb-2'>
                    {service.title}
                </a>
            ))}
        </div>
    );
};

export default Services;
