import React, { Fragment } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { Col, Collapse, Row } from 'antd';
import EmailInquiryForm from 'components/common/EmailInquiryForm';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { QuestionAndAnswer } from 'types';

/**
 * Props for Breadcrumb
 */
const breadcrumbs: Breadcrumb[] = [
    {
        name: 'トップページ',
        url: '/kouteki',
    },
    {
        name: 'Q&A',
        url: '/kouteki/inquiries',
    },
];

const { Panel } = Collapse;

const questionAndAnswer: QuestionAndAnswer[] = [
    {
        id: 1,
        question: 'サービスは無料で使えますか？',
        answer: 'はい。大同生命のお客さまであれば、ご契約有無に関わらずどなたでも無料でご利用いただけます。ご利用開始にあたっては、お客さまの基本情報をご登録いただく必要がありますので、まずは大同生命の営業担当者までご連絡をお願いいたします。'
    },
    {
        id: 2,
        question: 'サービス利用の流れを教えてください。',
        answer: '① 助成金・補助金・融資を検索\n' +
            '助成金・補助金・融資といった公的資金のうち、貴社のご状況に応じて、活用可能性のあるものを検索できます。\n\n' +
            '② 検索した公的資金を簡易診断\n' +
            '検索した公的資金について、チェック項目により簡易診断できます。\n\n' +
            '③ 専門家への相談サービス申込（各資金お問い合わせ窓口のご案内）※任意。\n' +
            '簡易診断ののち、助成金と代表的な補助金については、ご希望により専門家への相談サービスをお申し込みいただけます。（相談サービス対象外の資金は、お問い合わせ窓口をご案内いたします）\n\n' +
            '※専門家への相談サービスをご利用になる際は、ログインが必要です。'
    },
    {
        id: 3,
        question: 'ログインする方法が分からないので、教えてください。',
        answer: 'はじめにお客さまの基本情報をご登録いただく必要があります。登録の方法につきましては、大同生命の営業担当者までご連絡をお願いいたします。'
    },
    {
        id: 4,
        question: 'ログイン用のメールアドレス、パスワードを忘れてしまいました。',
        answer: 'メールアドレスをお忘れの方は、大同生命の営業担当者までご連絡をお願いいたします。\n' +
            'パスワードをお忘れの方は、画面右上の「ログイン」ボタンをクリックした後に、「パスワードをお忘れの方」をクリックしてください。パスワードの再設定ができます。'
    },
    {
        id: 5,
        question: '公的支援（公的資金）とは何ですか？',
        answer: 'このサービスでは、以下を総称して公的支援（公的資金）と称しております。\n' +
            '・助成金：厚生労働省管轄のもの\n' +
            '・補助金：厚生労働省以外のもの（都道府県のものを含む）\n' +
            '・融 資：日本政策金融公庫の融資'
    },
    {
        id: 6,
        question: 'このサービスでは、どの公的資金について、専門家に相談できますか？',
        answer: '以下の公的資金について専門家に相談できます。\n' +
            '・助成金：厚生労働省管轄のもの\n' +
            '・補助金：経済産業省管轄のもののうち、次の4つ\n' +
            '　　ものづくり補助金（ものづくり・商業・サービス生産性向上促進助成金）\n' +
            '　　小規模事業者持続化補助金\n' +
            '　　ＩＴ導入補助金\n' +
            '　　事業再構築補助金\n' +
            '※助成金に関するご相談は、社会保険労務士がご対応します。\n' +
            '※補助金に関するご相談は、中小企業診断士がご対応します'
    },
    {
        id: 7,
        question: '専門家とはどんな人ですか？',
        answer: 'サービス提供元である中小企業福祉事業団の会員社会保険労務士、または、会員中小企業診断士です。\n' +
            '※助成金に関するご相談は、社会保険労務士がご対応します。\n' +
            '※補助金に関するご相談は、中小企業診断士がご対応します。'
    },
    {
        id: 8,
        question: '相談はオンラインのみですか？',
        answer: '本サービスではオンラインでのご相談を基本としております。'
    },
    {
        id: 9,
        question: '面談の開始時刻は、10時00分など、ちょうどの時間からしかできないのですか？',
        answer: '本サービスでは、10時～16時までの間の、正時（ちょうどの時間）が開始時間となります。\n' +
            '10時～\n' +
            '11時～\n' +
            '12時～\n' +
            '13時～\n' +
            '14時～\n' +
            '15時～\n' +
            '16時～\n' +
            '※初回無料の面談時間は30分間です。'
    },
    {
        id: 10,
        question: '専門家に相談するのに費用はかかりますか？',
        answer: '初回30分は無料で相談できます。\n' +
            'なお、2回目以降のご相談や、手続き申請などの実務が発生する場合は、専門家に直接ご相談ください。'
    },
    {
        id: 11,
        question: '専門家に相談できるのは1回だけですか？',
        answer: '同じ専門家への無料相談は原則として1回だけとなります。他の専門家に相談することは可能です。（その場合も１回のみ）'
    },
];

const PublicSupportInquiries = () => {
    const PanelHeader = (question: string) => {
        return (
            <Row className='mt-3'>
                <Col md={1} xs={2}>
                    <span className='q-char'>Q</span>
                </Col>
                <Col md={23} xs={22}>
                    <p className='q-question'>{question}</p>
                </Col>
            </Row>
        );
    };

    return (
        <div className='inquiries'>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs}/>
            </section>
            <section className='content'>
                {questionAndAnswer.map((questionAndAnswer, key) =>
                    <Fragment key={key}>
                        <Collapse expandIconPosition='right'
                                  expandIcon={({isActive}) => isActive ? <UpOutlined/> : <DownOutlined/>}
                        >
                            <Panel header={PanelHeader(questionAndAnswer.question)} key={key}>
                                <Row>
                                    <Col md={1} xs={2}>
                                        <div className='a-char'>A</div>
                                    </Col>
                                    <Col md={23} xs={22}>
                                        <div className='a-content'>
                                            {questionAndAnswer.answer}
                                        </div>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                        <br/>
                    </Fragment>
                )}

                <br/>
                <EmailInquiryForm/>
            </section>
        </div>
    );
};

export default PublicSupportInquiries;
