import React, { useEffect } from 'react';
import { Alert, Button, Form, Input, Modal } from 'antd';
import {
    ForgotPasswordEmailSendingRequest,
    selectForgotPassword,
    sendForgotPasswordEmail,
} from 'app/slice/forgotPasswordSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { resetActionKey, selectSuccessModal, setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import sendingEmailSuccessIcon from 'assets/images/icon_send_mail.png';
import { LeftOutlined } from '@ant-design/icons';
type Props = {
    isVisible: boolean,
    onClose: () => void,
    onOpenLoginModal: () => void
}

const ForgotPasswordModal = (props: Props) => {
    const { isVisible, onClose, onOpenLoginModal } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { success = '', message, loading = false, status } = useAppSelector(selectForgotPassword);
    const successModal = useAppSelector(selectSuccessModal);

    const onFormSubmit = async (values: ForgotPasswordEmailSendingRequest) => {
        const { email } = values;
        dispatch(sendForgotPasswordEmail({ email }));
    };

    useEffect(() => {
        if (success) {
            const successModalPayload: SuccessModalState = {
                isShow: success,
                title: (
                    <>
                        <span className='h5'>認証メール送信完了</span>
                    </>
                ),
                message: (
                    <>
                        入力されたメールアドレスに認証メールを送信しました。<br />
                        メールに記載のURLからパスワードの再設定をお願いいたします。
                    </>
                ),
                withButton: true,
                buttonTitle: (
                    <>
                        <LeftOutlined className='arrow-icon' style={{marginRight: '10px'}} /><span>ログイン画面に戻る</span>
                    </>
                ),
                buttonActionKey: 'ON_FORGOT_PASSWORD_SENT',
            };
            onClose();
            dispatch(setSuccessState(successModalPayload));
        }
    }, [dispatch, onClose, onOpenLoginModal, success]);

    useEffect(() => {
        if (successModal.isShow === false && successModal.buttonActionKey === 'ON_FORGOT_PASSWORD_SENT') {
            dispatch(resetActionKey());
            onOpenLoginModal();
        }
    }, [dispatch, onOpenLoginModal, successModal.buttonActionKey, successModal.isShow]);

    const onClickCancel = () => {
        onClose();
        onOpenLoginModal();
    };

    return (
        <Modal className='forgot-password-modal' visible={isVisible}
               onCancel={onClickCancel} footer={null}
        >
            <div className='title'>パスワード再発行</div>
            <div className='subtitle'>
                確認のため、ご登録のメールアドレスをご入力ください。
            </div>
            {status === 'failed' && <Alert type={'error'} message={message} className='my-3' />}
            <Form
                form={form}
                layout='vertical'
                onFinish={onFormSubmit}
                requiredMark={false}
                autoComplete='off'
            >
                <Form.Item
                    name='email'
                    label={
                        <>メールアドレス<span className='required-text ml-2'>(必須)</span> </>
                    }
                    rules={[{ required: true, message: 'メールアドレスをご入力ください。' }]}
                >
                    <Input />
                </Form.Item>
                <Button type='primary' htmlType='submit' className='w-100 submit-button' loading={loading}>
                    {!loading ? '送信' : ''}
                </Button>
            </Form>
            <div className='helper-text'>
                ※ご登録のメールアドレスをお忘れの場合は、大同生命の営業担当者にご確認ください。
            </div>
        </Modal>
    );
};

export default ForgotPasswordModal;
