import React, { useEffect, useState } from 'react';
import 'assets/styles/app.scss';
import 'moment/locale/ja';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Layout from 'pages/main/Layout';
import WorkRegulationTermsOfService from 'pages/kisoku/WorkRegulationTermsOfService';
import WorkRegulationPrivacyPolicy from 'pages/kisoku/WorkRegulationPrivacyPolicy';
import WorkRegulationInquiries from 'pages/kisoku/WorkRegulationInquiries';
import WorkRegulationLanding from 'pages/kisoku/WorkRegulationLanding';
import PublicSupportTermsOfService from 'pages/kouteki/PublicSupportTermsOfService';
import PublicSupportPrivacyPolicy from 'pages/kouteki/PublicSupportPrivacyPolicy';
import PublicSupportInquiries from 'pages/kouteki/PublicSupportInquiries';
import PublicSupportLayout from 'pages/kouteki/PublicSupportLayout';
import Registration from 'pages/main/Registration';
import ForgotPassword from 'pages/main/ForgotPassword';
import Landing from 'pages/main/Landing';
import Maintenance from 'pages/main/Maintenance';
import WorkRegulationLayout from 'pages/kisoku/WorkRegulationLayout';
import PublicSupportLanding from 'pages/kouteki/PublicSupportLanding';
import DiagnoseResult from 'pages/kouteki/DiagnoseResult';
import LoginModal from 'components/landing/LoginModal';
import ForgotPasswordModal from 'components/landing/ForgotPasswordModal';
import SuccessModal from 'components/common/SuccessModal';
import { reset } from 'app/slice/forgotPasswordSlice';
import ForgotPasswordSuccess from 'pages/main/ForgotPasswordSuccess';
import PublicSupportSearch from 'pages/kouteki/PublicSupportSearch';
import DashboardLayout from 'pages/dashboard/DashboardLayout';
import DashboardManagement from 'pages/dashboard/DashboardManagement';
import Notifications from 'pages/dashboard/Notifications';
import MeetingCalendar from 'pages/dashboard/MeetingCalendar';
import ConsultationList from 'pages/dashboard/ConsultationList';
import { fetchUserData, selectAuth } from 'app/slice/authSlice';
import FundDetails from 'pages/kouteki/FundDetails';
import ConsultationDetails from 'pages/dashboard/ConsultationDetails';
import KoutekiSpecialistReviewList from 'pages/kouteki/SpecialistReviewList';
import KoutekiSpecialistReviewDetails from 'pages/kouteki/SpecialistReviewDetails';
import KisokuSpecialistReviewList from 'pages/kisoku/SpecialistReviewList';
import KisokuSpecialistReviewDetails from 'pages/kisoku/SpecialistReviewDetails';
import WorkingRegulationService from 'pages/dashboard/WorkingRegulationService';
import WorkingRegulationMeetings from 'pages/dashboard/WorkingRegulationMeetings';
import WorkingRegulationMeetingDetails from 'pages/dashboard/WorkingRegulationMeetingDetails';
import ScrollToTop from 'components/common/ScrollToTop';
import WorkRegulationTopic from 'pages/kisoku/WorkRegulationTopic';
import { getNotificationCount, selectNotification } from 'app/slice/notificationSlice';
import WorkRegulationTopicList from 'pages/kisoku/WorkRegulationTopicList';
import Services from 'pages/dashboard/Services';
import PublicSupportTopicList from 'pages/kouteki/PublicSupportTopicList';
import PublicSupportTopic from 'pages/kouteki/PublicSupportTopic';
import DiagnoseResultSchedule from 'pages/kouteki/DiagnoseResultSchedule';
import DiagnoseResultScheduleFinished from 'pages/kouteki/DiagnoseResultScheduleFinished';

const App = () => {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
    const dispatch = useAppDispatch();
    const { token } = useAppSelector(selectAuth);
    const { notificationCount } = useAppSelector(selectNotification);
    const onCloseLoginModal = () => setIsLoginModalVisible(false);
    const onCloseForgotPasswordModal = () => {
        setIsForgotPasswordModalVisible(false);
        dispatch(reset());
    };
    const onOpenForgotPasswordModal = () => setIsForgotPasswordModalVisible(true);
    const onOpenLoginModal = () => setIsLoginModalVisible(true);

    useEffect(() => {
        if (token) {
            dispatch(fetchUserData());
        }
    }, [token, dispatch]);

    useEffect(() => {
        if (token) {
            dispatch(getNotificationCount());
        }
    }, [token, dispatch, notificationCount]);

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<Layout onOpenLoginModal={onOpenLoginModal} />}>
                    <Route index element={<Landing />} />
                    <Route path='forgot-password/:token/success' element={<ForgotPasswordSuccess />} />
                    <Route path='forgot-password/:token' element={<ForgotPassword />} />
                    <Route path='registration/:uuid' element={<Registration />} />
                </Route>
                <Route path='/kouteki' element={<PublicSupportLayout onOpenLoginModal={onOpenLoginModal} />}>
                    <Route index element={<PublicSupportLanding />} />
                    <Route path='terms-of-service' element={<PublicSupportTermsOfService />} />
                    <Route path='privacy-policy' element={<PublicSupportPrivacyPolicy />} />
                    <Route path='funds/search' element={<PublicSupportSearch />} />
                    <Route path='funds/:code/details' element={<FundDetails />} />
                    <Route path='funds/diagnose' element={<DiagnoseResult onOpenLoginModal={onOpenLoginModal} />} />
                    <Route path='funds/diagnose/schedule' element={<DiagnoseResultSchedule />} />
                    <Route path='funds/diagnose/schedule/finished' element={<DiagnoseResultScheduleFinished />} />
                    <Route path='inquiries' element={<PublicSupportInquiries />} />
                    <Route path='topics' element={<PublicSupportTopicList />} />
                    <Route path='topics/:uuid' element={<PublicSupportTopic />} />
                </Route>
                <Route path='/kisoku' element={<WorkRegulationLayout onOpenLoginModal={onOpenLoginModal} />}>
                    <Route index element={<WorkRegulationLanding />} />
                    <Route path='terms-of-service' element={<WorkRegulationTermsOfService />} />
                    <Route path='privacy-policy' element={<WorkRegulationPrivacyPolicy />} />
                    <Route path='inquiries' element={<WorkRegulationInquiries />} />
                    <Route path='topics' element={<WorkRegulationTopicList />} />
                    <Route path='topics/:uuid' element={<WorkRegulationTopic />} />
                </Route>
                <Route path='/dashboard' element={<DashboardLayout notificationCount={notificationCount} />}>
                    <Route path='management' element={<DashboardManagement />} />
                    <Route path='calendar' element={<MeetingCalendar />} />
                    <Route path='notifications' element={<Notifications />} />
                    <Route path='kouteki'>
                        <Route path='consultation-list' element={<ConsultationList />} />
                        <Route path='consultation/:uuid/details'>
                            <Route index element={<ConsultationDetails />} />
                            <Route path=':specialist/schedule' element={<ConsultationDetails />} />
                        </Route>
                        <Route path='specialist-review' element={<KoutekiSpecialistReviewList />} />
                        <Route path='specialist-review/details/:status' element={<KoutekiSpecialistReviewDetails />} />
                    </Route>
                    <Route path='working-regulation'>
                        <Route index element={<WorkingRegulationService />} />
                        <Route path='meetings' element={<WorkingRegulationMeetings />} />
                        <Route path='meetings/:uuid' element={<WorkingRegulationMeetingDetails />} />
                        <Route path='specialist-review' element={<KisokuSpecialistReviewList />} />
                        <Route path='specialist-review/details/:status' element={<KisokuSpecialistReviewDetails />} />
                    </Route>
                    <Route path='services' element={<Services />} />
                </Route>
                <Route path='maintenance' element={<Maintenance />} />
            </Routes>

            <LoginModal isVisible={isLoginModalVisible}
                        onClose={onCloseLoginModal}
                        onOpenForgotPasswordModal={onOpenForgotPasswordModal}
            />
            <ForgotPasswordModal isVisible={isForgotPasswordModalVisible}
                                 onClose={onCloseForgotPasswordModal}
                                 onOpenLoginModal={onOpenLoginModal}
            />
            <SuccessModal />
        </BrowserRouter>
    );
};

export default App;
