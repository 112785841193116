import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getNotifications, getNotViewedNotifications, markingAsViewed } from 'api/notification';

type NotificationState = {
    loading: boolean,
    success: boolean,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
    notificationData: Notification[],
    notificationCount: number,
}

type NotificationData = {
    id: number,
    message: string,
    viewed_at: string,
    uuid: string,
    type: number
    application: {
        uuid: string,
    },
    created_at: string,
}

type Notification = {
    id: number,
    message: string,
    createdAt: string,
    viewedAt: string,
    uuid: string,
    type: number,
}

/**
 * GET user's notifications
 **/
export const getUserNotification = createAsyncThunk(
    'users/notification',
    async (page: number, { dispatch, rejectWithValue }) => {
        try {
            const response = await getNotifications(page);
            dispatch(setNotificationList(response.data));
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

export type MarkAsViewedRequest = {
    id: number
}

/**
 * PATCH user's notification as viewed
 **/
export const markAsViewed = createAsyncThunk(
    'users/notification',
    async (data: MarkAsViewedRequest, { dispatch, rejectWithValue }) => {
        try {
            const { id } = data;
            const response = await markingAsViewed(id);
            dispatch(getNotificationCount());
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * GET user's notifications
 **/
export const getNotificationCount = createAsyncThunk(
    'users/notification',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await getNotViewedNotifications();
            dispatch(setNotificationCount(response.data));
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

const initialState: NotificationState = {
    loading: false,
    success: false,
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    notificationData: [] as Notification[],
    notificationCount: 0,
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        setNotificationList: (state, { payload }) => {
            const notificationData = payload.data;
            const convertedData: Notification[] = [];
            notificationData.forEach((notifs: NotificationData) => {
                convertedData.push({
                    id: notifs.id,
                    message: notifs.message,
                    type: notifs.type,
                    viewedAt: notifs.viewed_at,
                    uuid: notifs?.application?.uuid,
                    createdAt: notifs?.created_at,
                });
            });
            state.total = payload.pagination.total;
            state.perPage = payload.pagination.per_page;
            state.currentPage = payload.pagination.current_page;
            state.lastPage = payload.pagination.last_page;
            state.notificationData = convertedData;
        },
        setNotificationCount: (state, { payload }) => {
            state.notificationCount = payload?.data?.length;
        },
        reset: (state: NotificationState) => {
            state.success = false;
            state.loading = false;
        },
    },
});

export const selectNotification = (state: RootState) => state.notification;

export const {
    reset,
    setNotificationList,
    setNotificationCount
} = notificationSlice.actions;
