import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ReactNode } from 'react';

export type SuccessModalState = {
    isShow: boolean,
    title: string | ReactNode,
    message: string | ReactNode,
    withButton: boolean, 
    image?: string,
    buttonLink?: string,
    buttonTitle?: string | ReactNode,
    buttonActionKey?: string
}

export const successModalSlice = createSlice({
    name: 'successModal',
    initialState: {
        image: '',
        title: '',
        message: '',
        withButton: false,
        buttonLink: '',
        buttonTitle: '',
        buttonActionKey: '',
        isShow: false,
    } as SuccessModalState,
    reducers: {
        setSuccessState: (state: SuccessModalState, action: PayloadAction<SuccessModalState>) => {
            state.image = action.payload.image;
            state.title = action.payload.title;
            state.message = action.payload.message;
            state.withButton = action.payload.withButton;
            state.buttonLink = action.payload.buttonLink;
            state.buttonTitle = action.payload.buttonTitle;
            state.buttonActionKey = action.payload.buttonActionKey;
            state.isShow = action.payload.isShow;
        },
        reset: (state: SuccessModalState) => {
            state.image = '';
            state.title = '';
            state.message = '';
            state.withButton = false;
            state.buttonLink = '';
            state.buttonTitle = '';
            state.isShow = false;
        },
        resetActionKey: (state: SuccessModalState) => {
            state.buttonActionKey = '';
        },
    },
});

export const selectSuccessModal = (state: RootState) => state.successModal;
export const { setSuccessState, reset, resetActionKey } = successModalSlice.actions;

