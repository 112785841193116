import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleList } from 'types';
import { Rule } from 'antd/lib/form';
import { useLocation, useSearchParams } from 'react-router-dom';

/**
 * Set body id for styling
 *
 * @param {string} style
 */
export const useStyle = (...style: StyleList[]) => {
    document.body.id = style.join(' ');
};

/**
 * Scroll to top
 */
export const scrollToTop = (behavior?: 'smooth' | 'auto') => window.scroll({
    top: 0,
    left: 0,
    behavior: behavior,
});

/**
 * Rule for checking password match
 *
 * @param {string} fieldName
 * @param {string} message
 * @returns {({getFieldValue}: {getFieldValue: any}) => {validator(_, value): (Promise<never>)}}
 */
export const validatePasswordConfirmation: (fieldName: string, message: string) => Rule = (fieldName, message) => {
    return ({ getFieldValue }) => ({
        validator(_, value) {
            if (value && getFieldValue(fieldName) !== value) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};
/**
 * Change format of dates in 2022年11月29日 to 2022.11.29
 *
 * @param {string} date
 * @returns {string}
 */
export const changeDateFormat = (date: string) => {
    return date.replace(/年|月|日/g, (match) => {
        return match === "年" || match === "月" ? "." : "";
    });
};

/**
 * Change format of dates
 *
 * @param {string} date
 * @returns {string}
 */
// util/dateFormatter.js
export const formatDate = (rawDate: string): string => {
    if (!rawDate) return "ー";
    const date = new Date(rawDate);

    // Extract year, month, and day from the date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if single digit
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if single digit

    // Return formatted date string
    return `${year}年${month}月${day}日`;
};

// Function to get the Japanese weekday from a Date object
const getJapaneseWeekday = (date: Date) => {
    const weekdays = ['日', '月', '火', '水', '木', '金', '土']; // 日曜日 to 土曜日
    const dayOfWeek = date.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    return weekdays[dayOfWeek]; // Return the corresponding weekday character
};

// Function to format the date with the Japanese weekday
export const formatDateWithWeekday = (scheduleDate: string | number | Date) => {
    // Convert the input date string (e.g., '2025-01-30') to a Date object
    const dateObj = new Date(scheduleDate);

    // Get the Japanese weekday (木, 水, etc.)
    const japaneseWeekday = getJapaneseWeekday(dateObj);

    // Format the date as YYYY年MM月DD日 (木)
    const formattedDate = `${dateObj.getFullYear()}年${(dateObj.getMonth() + 1).toString().padStart(2, '0')}月${dateObj
        .getDate()
        .toString()
        .padStart(2, '0')}日（${japaneseWeekday}）`;

    return formattedDate;
};

export const isDevMode = () => {
    return process.env.NODE_ENV === 'development' || window.location.hostname.includes('dev');
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange();
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return isMobile;
};

export const windowOpen = (url: string, target?: string, features?: string) => {
    return new Promise<void>(resolve => {
        let win = window.open(url, target, features);

        if (win === null) {
            window.location.href = url;
            resolve();
        }

        let intervalHandler = setInterval(() => {
            if (win?.closed) {
                clearInterval(intervalHandler);
                resolve();
            }
        }, 100);
    });
};

export const useParamsState = <T = string>(
    key: string,
    defaultValue: any = null,
    transform: ((value: any) => any) = (value) => value,
): [T, (value: T) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    return [
        useMemo(
            () => transform(searchParams.get(key) ?? defaultValue),
            [defaultValue, key, searchParams, transform],
        ),
        useCallback((value: T) => {
            const query = value as any;

            if (searchParams.get(key) !== query) {
                setSearchParams({ ...Object.fromEntries(searchParams), [key]: query });
            }
        }, [key, searchParams, setSearchParams]),
    ];
};
