import { useEffect, useState } from 'react';
import {
    CANCEL_APPLICATION,
    cancelApplication,
    CancelConsultationRequest,
    getApplicationDetails,
    selectApplicationDetails,
} from 'app/slice/applicationDetailsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LeftOutlined } from '@ant-design/icons';
import { ApplicationDetails } from 'app/slice/applicationDetailsSlice';
import { Button, Form, Modal } from 'antd';
import CustomModal from 'components/common/CustomModal';
import moment from 'moment';
import errorIcon from 'assets/images/error.png';

type Props = {
    uuid: string;
    isVisible: boolean;
    reasonCancelInput: string;
    application: ApplicationDetails;
    onClose: () => void;
    onClickConfirm: () => void;
    onClickReturn: () => void;
};

const ConsultationConfirmCancelModal = (props: Props) => {
    const { uuid, isVisible, reasonCancelInput, application, onClose, onClickConfirm, onClickReturn } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { type, success, loading } = useAppSelector(selectApplicationDetails);

    const [isApplicationCreationErrorVisible, setIsApplicationCreationErrorVisible] = useState(false);
    const onCloseApplicationCreationErrorModal = () => setIsApplicationCreationErrorVisible(false);

    const onSubmit = () => {
        const today = moment();
        const formattedCurrentDate = today.format('YYYY.MM.DD');
        const currentScheduleDate = application.schedule;
        const formattedCurrentScheduleDate = currentScheduleDate!.replace('年', '.').replace('月', '.').replace('日', '');

        if (formattedCurrentDate === formattedCurrentScheduleDate) {
            onClose();
            setIsApplicationCreationErrorVisible(true);
        } else {
            dispatch(
                cancelApplication({
                    uuid: uuid,
                    reason: reasonCancelInput,
                } as CancelConsultationRequest)
            );
        }
    };

    useEffect(() => {
        // Make sure to update application details after cancellation of meeting.
        if (type === CANCEL_APPLICATION && success) {
            dispatch(getApplicationDetails(uuid));
            onClickConfirm();
        }
    }, [dispatch, onClickConfirm, success, type, uuid]);

    return (
        <>
            <Modal className='consultation-details-cancel-modal' width={800} visible={isVisible} footer={null} onCancel={onClose}>
                <div className='title-modal'>下記の面談予約をキャンセルします</div>
                <Form form={form} onFinish={onSubmit} layout='vertical'>
                    <div className='cancel-info'>
                        <div className='w-25 p-3 cancel-info-left'>公的資金名称</div>
                        <div className='w-75 p-3'>
                            {application?.funds?.map((fund) => (
                                <div key={fund?.code} className='consultation-list-card-title'>
                                    {fund?.title}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='cancel-info'>
                        <div className='w-25 p-3 cancel-info-left'>面談日時</div>
                        <div className='w-75 p-3'>{application.scheduleDatetime}</div>
                    </div>
                    <div className='cancel-info border-bottom'>
                        <div className='w-25 p-3 cancel-info-left'>キャンセル理由</div>
                        <div className='w-75 p-3'>{reasonCancelInput}</div>
                    </div>
                    <div className='form-buttons mt-4'>
                        <Button className='general-button cancel-button me-4' onClick={onClickReturn}>
                            <span className='me-3'>
                                <LeftOutlined />
                            </span>
                            戻る
                        </Button>
                        <Button className='general-button submit-button' htmlType='submit' loading={loading}>
                            キャンセルを確定する
                        </Button>
                    </div>
                </Form>
            </Modal>

            {/*Modal when an application cannot be cancelled*/}
            <CustomModal
                isVisible={isApplicationCreationErrorVisible}
                onClose={onCloseApplicationCreationErrorModal}
                title=''
                icon={errorIcon}
                text='当日に予約変更はできません。当日の変更連絡は大同生命の営業担当者までご連絡ください。'
                showCancelButton={false}
                showConfirmButton={true}
                confirmButtonText='閉じる'
                confirmOnClick={onCloseApplicationCreationErrorModal}
            />
        </>
    );
};

export default ConsultationConfirmCancelModal;
