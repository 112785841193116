import React, { useEffect } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, Skeleton } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getTopicList, selectTopic } from 'app/slice/topicSlice';
import { range } from 'lodash';
import { NotificationType } from 'enums';

const breadcrumbs: Breadcrumb[] = [
    {
        name: 'トップページ',
        url: '/kouteki',
    },
    {
        name: '公的支援に関連するトピックス',
        url: '/kouteki/topics',
    },
];

const PublicSupportTopicList = () => {
    const dispatch = useAppDispatch();
    const { data, loading } = useAppSelector(selectTopic);

    useEffect(() => {
        dispatch(getTopicList({
            type: NotificationType.KOTEKI,
            perPage: 50,
        }));
    }, [dispatch]);

    return (
        <>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs} />
            </section>

            <section className='home-content topic-list'>
                <div className='title'>公的支援に関連するトピックス</div>
                <div className='topics'>
                    {loading ? (
                        <div className='my-3'>
                            {range(0, 5).map(key => (
                                <Skeleton.Button key={key} active size='large' block className='mb-2' />
                            ))}
                        </div>
                    ) : data.map((topic, key) => (
                        <Card size='small' key={key}>
                            <Row>
                                <Col md={3} xs={24} className='topic-date-col'>
                                    <div className='topic-date-container'>{topic.sendDateList}</div>
                                </Col>
                                <Col md={18} xs={24} className='topic-text-col'>
                                    {topic.title}
                                </Col>
                                <Col md={3} xs={24} className='topic-button-col'>
                                    <Link to={`/kouteki/topics/${topic.uuid}`}>
                                        <Button className='secondary small'>詳細</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </div>
            </section>
        </>
    );
};

export default PublicSupportTopicList;
