import { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import avatar from 'assets/images/diagnosis_avatar.png';
import avatar_consult from 'assets/images/avatar_consult.png';
import { useLocation } from 'react-router-dom';
import DiagnoseAllowConsultation from './DiagnoseAllowConsultation';
import DiagnoseNotAllowConsultation from './DiagnoseNotAllowConsultation';
import { FundsLocationState } from 'pages/kouteki/DiagnoseResult';
import { useAppDispatch } from 'app/hooks';
import { setFundList } from 'app/slice/meetingSlice';

type Props = {
    handleDiagnoseScheduleSubmit: () => void;
};

const DiagnoseResultValid = (props: Props) => {
    const { funds } = useLocation().state as FundsLocationState;
    const { handleDiagnoseScheduleSubmit } = props;
    const [disabled, setDisabled] = useState<boolean>(false);
    const [checked, setChecked] = useState<string[]>([]);
    const fundType = funds.some((e) => e.type === 4);
    const [fundCount, setFundCount] = useState(funds.length);
    const [isFundMultiple, setIsFundMultiple] = useState(false);
    const [isFundAllowConsultation, setIsFundAllowConsultation] = useState(false);
    const [isFundNotAllowConsultation, setIsFundNotAllowConsultation] = useState(false);
    const [counter, setCounter] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        let count = funds.length;
        let isAllowConsultation = false;
        let isNotAllowConsultation = false;
        let allowConsultationCounter = 0;

        // Adjust fundCount based on fundType
        if (fundType) {
            count -= 1;
        }

        setFundCount(count);

        // Set isFundMultiple based on fundCount
        setIsFundMultiple(fundCount > 1);

        funds
            .filter((fund) => fund.title !== '基本確認項目' && fund.result === true)
            .map((fund) => {
                if (fund.allowConsultation && fund.requirements.length !== 0) {
                    isAllowConsultation = true;
                    allowConsultationCounter++;
                } else {
                    isNotAllowConsultation = true;
                }
            });

        setIsFundAllowConsultation(isAllowConsultation);
        setIsFundNotAllowConsultation(isNotAllowConsultation);
        setCounter(allowConsultationCounter);
    }, [funds, fundType, fundCount]);

    const handleCheckboxChange = (newCheckedList: string[]) => {
        //Update the checked list
        setChecked(newCheckedList);
        //Set only checked funds that allow consultation
        dispatch(setFundList(newCheckedList));
        //Set disable to true if no checked
        setDisabled(newCheckedList.length > 0);
    };

    return (
        <>
            <Card className='diagnose-list'>
                <Space size={12}>
                    <img className='diagnosis-avatar' src={avatar} alt='avatar' width={96} height={96} />
                    <h3 className='header-title'>お取り組み次第で以下の公的資金を活用できる可能性があります。</h3>
                </Space>

                {isFundAllowConsultation && (
                    <Card
                        size='small'
                        title={
                            <>
                                以下の公的資金は、専門家相談サービス<span className='title-card'>「専門家相談窓口」</span>
                                をご活用いただけます。
                            </>
                        }
                        className='card-content'
                    >
                        <p className='first-content'>
                            「専門家相談窓口」では、公的資金の具体的な内容・申請要件・取り組むべき項目など、貴社に役立つ有益な情報をご提供いたします。ぜひご活用ください。
                        </p>
                        <ul className='list-content'>
                            <li>
                                一括診断の場合は「相談項目に追加する」ボタンを選択すると、専門家相談が利用できます。相談項目は複数選択可です。
                            </li>
                            <li>「参考ページ（外部リンク）」より公的資金の詳細を確認することができます。</li>
                        </ul>

                        <DiagnoseAllowConsultation onCheckboxChange={handleCheckboxChange} checked={checked} />

                        <Row>
                            <Col span={24}>
                                <Button
                                    className='expert-consultation'
                                    onClick={handleDiagnoseScheduleSubmit}
                                    disabled={isFundMultiple && counter > 1 ? !disabled : disabled}
                                >
                                    <Row>
                                        <img className='diagnosis-avatar' src={avatar_consult} alt='avatar-consult' />
                                        <h3 className='btn-text'> 専門家相談窓口を利用する</h3>
                                    </Row>
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )}

                <br />

                {isFundNotAllowConsultation && (
                    <div>
                        <div className='info-title'>
                            <p className='info'>
                                以下の公的資金は、専門家相談サービスの対象外です。「参考ページ（外部リンク）」先の管轄機関情報をご活用ください。
                            </p>
                        </div>

                        <div className='info-subtitle'>
                            <p>公的資金の詳細・要件等につきましては、「参考ページ（外部リンク）」先のお問い合わせ窓口をご利用ください。</p>
                        </div>

                        <DiagnoseNotAllowConsultation />
                    </div>
                )}
            </Card>
        </>
    );
};

export default DiagnoseResultValid;
