import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { fetchFundDetails } from 'api/funds';

export type FundDetailsState = {
    type: string,
    success: boolean | null,
    message: string,
    loading: boolean,
    data: FundDetails
}

export type FundDetailsRequest = {
    coverage_type?: string,
    type?: string,
    capital_range?: string,
    employee_count_range?: string,
    industry?: string,
    prefecture?: string,
    years_established_range?: string,
    page?: string,
    per_page?: string
}

export type FundRequirement = {
    code: string,
    type: number,
    title: string,
    requirements: { content: string }[],
    url: string,
    allowConsultation: boolean
}

export type FundDetailsResponse = {
    code: string,
    url: string,
    type: number,
    jurisdiction: string,
    title: string,
    overview: string,
    target_groups: string,
    amount: string,
    application_period: string,
    created_at: string,
    updated_at: string,
    label: string,
    fund_requirements: FundRequirement[],
    intended_use: string,
    repayment_period: string,
    interest_rate: string,
    guarantor: string,
    allow_consultation: boolean
}

export type FundDetails = {
    code: string,
    url: string,
    type: number,
    jurisdiction: string,
    title: string,
    overview: string,
    targetGroups: string,
    amount: string,
    applicationPeriod: string,
    createdAt: string,
    updatedAt: string,
    label: string,
    fundRequirements: FundRequirement[],
    intendedUse: string,
    repaymentPeriod: string,
    interestRate: string,
    guarantor: string,
    allowConsultation: boolean
}

export const getFundDetails = createAsyncThunk(
    'funds/details',
    async (code: string, { dispatch, rejectWithValue }) => {
        try {
            let response = await fetchFundDetails(code);

            if (response.data.success === true) {
                dispatch(setFund(response.data.data));
                return response.data.data;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const fundDetailsSlice = createSlice({
    name: 'fundDetails',
    initialState: {
        type: '',
        success: null,
        message: '',
        loading: true,
        data: {},
    } as FundDetailsState,
    reducers: {
        setFund: (state, { payload }: { payload: FundDetailsResponse }) => {
            state.data = {
                code: payload.code,
                url: payload.url,
                type: payload.type,
                jurisdiction: payload.jurisdiction,
                title: payload.title,
                overview: payload.overview,
                targetGroups: payload.target_groups,
                amount: payload.amount,
                applicationPeriod: payload.application_period,
                createdAt: payload.created_at,
                updatedAt: payload.updated_at,
                label: payload.label,
                fundRequirements: payload.fund_requirements,
                intendedUse: payload.intended_use,
                repaymentPeriod: payload.repayment_period,
                interestRate: payload.interest_rate,
                guarantor: payload.guarantor,
                allowConsultation: payload.allow_consultation,
            };
        },
    },
    extraReducers: (builder) => {
        // getFundDetails action pending
        builder.addCase(getFundDetails.pending, (state: FundDetailsState) => {
            state.loading = true;
        });
        // getFundDetails action rejected
        builder.addCase(getFundDetails.rejected, (state: FundDetailsState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // getFundDetails action fulfilled
        builder.addCase(getFundDetails.fulfilled, (state: FundDetailsState) => {
            state.loading = false;
            state.success = true;
        });
    },
});

export const { setFund } = fundDetailsSlice.actions;
export const selectFundDetails = (state: RootState) => state.fundDetails;
