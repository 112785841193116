import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import {
    cancelConsultationApi,
    CancelConsultationRequest,
    getApplicationDetailsApi,
    getSpecialistProfileApi,
    noChukidanConsultationRequestApi,
    requestChukidanConsultationApi,
    Schedule,
    scheduleConsultationApi,
    ScheduleConsultationRequest,
} from 'api/application';
import { RootState } from 'app/store';

export type { CancelConsultationRequest, ScheduleConsultationRequest, Schedule } from 'api/application';

export const GET_APPLICATION_DETAILS = 'GET_APPLICATION_DETAILS';
export const NO_CHUKIDAN_CONSULTATION_REQUEST = 'NO_CHUKIDAN_CONSULTATION_REQUEST';
export const REQUEST_CHUKIDAN_CONSULTATION = 'REQUEST_CHUKIDAN_CONSULTATION';
export const CANCEL_APPLICATION = 'CANCEL_APPLICATION';
export const SET_APPLICATION_SCHEDULE = 'SET_APPLICATION_SCHEDULE';

// Application Details State
type ApplicationDetailsState = {
    success: boolean | null;
    loading: boolean;
    data: ApplicationDetails;
    type: string;
};

type Insurance = {
    code: string;
    createdAt: string;
    name: string;
    pivot: Pivot;
    updatedAt: string;
};

type Pivot = {
    companyId: number;
    insurancePlanId: number;
};

type Company = {
    address: string;
    allowSharoushi: boolean;
    allowShindashi: boolean;
    employeeCountRanges: string;
    industry: string;
    insurances: Insurance[];
    laborRegulationsCompliant: number;
    name: string;
    partTimeEmployeeCount: number;
    personInCharge: string;
};

// Application Details Data Structure
export type ApplicationDetails = {
    uuid: string;
    status: number;
    label: {
        content: string;
        color: string;
    };
    schedule: string | null;
    scheduleDatetime: string | null;
    isDeadline: boolean | null;
    joinUrl: string | null;
    daidoJoins: boolean | null;
    password: string | null;
    specialist: Specialist | null;
    funds: ApplicationFund[];
    specialists: Specialist[] | null;
    chukidanSchedules: Schedule[] | null;
    cancellationReason: string;
    cancellationDate: string;
    company: Company;
    emergencyContactNumber: string;
    meetingCreatedDate: string;
    meetingRemarks: string;
    personInCharge: string;
    meeting_created_date: string;
    cancellation_date: string;
};

// Application Fund Structure
export type ApplicationFund = {
    code: string;
    type: number;
    label: string;
    title: string;
    fundRequirements: [
        {
            title: string;
            requirements: [
                {
                    content: string;
                }
            ];
        }
    ];
    applicationRemarks: string | null;
};

// Expected Json Structure of API response
// Main difference is usage of snake_case
type ApplicationFundData = {
    code: string;
    type: number;
    label: string;
    title: string;
    fund_requirements: [
        {
            title: string;
            requirements: [
                {
                    content: string;
                }
            ];
        }
    ];
    application_remarks: string;
};

export type Specialist = {
    profileImageUrl: string;
    subProfileImageUrl: string;
    uuid: string;
    role: string;
    label: string;
    email: string;
    phone: string | null;
    name: string;
    officeName: string;
    address: string;
    schedules: Schedule[] | null;
};

export type SpecialistProfileData = {
    profileImageUrl: string;
    subProfileImageUrl: string;
    uuid: string;
    role: string;
    label: string;
    name: string;
    nameKana: string;
    prefecture: string;
    address: string;
    buildingName: string;
    prefectures: string[];
    otherAreas: string;
    otherAddress?: string;
    officePR?: string;
    officeHistory?: string;
    qualifications?: string;
    interview?: string;
    fields: string[];
    industries: string[];
    employeeCountRanges: string[];
    phone?: string;
    email?: string;
    twitterUrl?: string;
    facebookUrl?: string;
    homepageUrl?: string;
    businessManualUrl?: string;
    specialistCertificationImages?: string[];
};

/**
 * GET applications list
 **/
export const getApplicationDetails = createAsyncThunk(
    '/company/applications/details',
    async (uuid: string, { dispatch, rejectWithValue }) => {
        const response = await getApplicationDetailsApi(uuid);
        try {
            if (response.data.success === true) {
                dispatch(setApplicationDetails(response.data));
                return response.data;
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(false);
        }
    }
);

/**
 * POST Application Consultation / Meeting Schedule
 */
export const setApplicationSchedule = createAsyncThunk(
    '/company/applications/:uuid/schedule',
    async (data: ScheduleConsultationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await scheduleConsultationApi(data);

            if (response.data.success === true) {
                dispatch(setSchedule(response.data));
                dispatch(setApplicationDetails(response.data as ApplicationDetails));
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * POST Application Consultation / Meeting Schedule
 */
export const cancelApplication = createAsyncThunk(
    '/company/applications/:uuid/cancel',
    async (data: CancelConsultationRequest, { rejectWithValue }) => {
        try {
            let response = await cancelConsultationApi(data);

            if (response.data.success === true) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * GET Specialist Profile
 **/
export const getSpecialistProfile = createAsyncThunk(
    '/company/applications/:uuid/specialist/:specialist',
    async (data: { uuid: string; specialist_uuid: string }, { rejectWithValue }) => {
        try {
            const response = await getSpecialistProfileApi(data.uuid, data.specialist_uuid);

            if (response.data.success === true) {
                let data = response.data.data as SpecialistProfileData;
                data.profileImageUrl = response.data.data.profile_image_url;
                data.subProfileImageUrl = response.data.data.sub_profile_image_url;
                data.nameKana = response.data.data.name_kana;
                data.buildingName = response.data.data.building_name;
                data.otherAreas = response.data.data.other_areas;
                data.otherAddress = response.data.data.other_address;
                data.officePR = response.data.data.office_pr;
                data.officeHistory = response.data.data.office_history;
                data.employeeCountRanges = response.data.data.employee_count_ranges;
                data.twitterUrl = response.data.data.twitter_url;
                data.facebookUrl = response.data.data.facebook_url;
                data.homepageUrl = response.data.data.homepage_url;
                data.businessManualUrl = response.data.data.business_manual_url;
                data.specialistCertificationImages = response.data.data.specialist_certification_images;

                return data;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * POST Application Consultation / Meeting Schedule
 */
export const requestChukidanConsultation = createAsyncThunk(
    '/company/applications/:uuid/request-chukidan',
    async (uuid: string, { rejectWithValue }) => {
        try {
            let response = await requestChukidanConsultationApi(uuid);

            if (response.data.success === true) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * POST Application Consultation / Meeting Schedule
 */
export const noChukidanConsultationRequest = createAsyncThunk(
    '/company/applications/:uuid/no-match',
    async (uuid: string, { rejectWithValue }) => {
        try {
            let response = await noChukidanConsultationRequestApi(uuid);

            if (response.data.success === true) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

/**
 * Create Application Slice
 */
export const applicationDetailsSlice = createSlice({
    name: 'application',
    initialState: {
        success: false,
        loading: false,
        data: {} as ApplicationDetails,
        type: '',
    } as ApplicationDetailsState,
    reducers: {
        reset: (state: ApplicationDetailsState) => {
            state.success = false;
            state.loading = true;
            state.data = {} as ApplicationDetails;
            state.type = '';
        },
        setApplicationDetails: (state, { payload }) => {
            state.data.uuid = payload.data.uuid;
            state.data.status = payload.data.status;
            state.data.label = payload.data.label;
            state.data.schedule = payload.data.schedule;
            state.data.password = payload.data.password;

            // snake_case to camelCase
            state.data.isDeadline = payload.data.is_deadline;
            state.data.scheduleDatetime = payload.data.schedule_datetime;
            state.data.joinUrl = payload.data.join_url;
            state.data.daidoJoins = payload.data.daido_joins;

            if (payload.data.specialist) {
                state.data.specialist = payload.data.specialist;
                state.data.specialist!.officeName = payload.data.specialist.office_name;
                state.data.specialist!.profileImageUrl = payload.data.specialist.profile_image_url || '';
                state.data.specialist!.subProfileImageUrl = payload.data.specialist.sub_profile_image_url || '';
            }

            const convertedData: ApplicationFund[] = [];
            payload.data.funds.forEach((fund: ApplicationFundData) => {
                convertedData.push({
                    code: fund.code,
                    type: fund.type,
                    label: fund.label,
                    title: fund.title,
                    fundRequirements: fund.fund_requirements,
                    applicationRemarks: fund.application_remarks,
                });
            });

            state.data.funds = convertedData;
            state.data.specialists = payload.data.specialists;
            if (state.data.specialists) {
                state.data.specialists.forEach((specialist: any) => {
                    specialist.profileImageUrl = specialist.profile_image_url;
                    specialist.subProfileImageUrl = specialist.sub_profile_image_url;
                    specialist.officeName = specialist.office_name;
                });
            }

            if (payload.data.chukidan_schedules) {
                state.data.chukidanSchedules = payload.data.chukidan_schedules;
            }

            state.data.cancellationReason = payload.data.cancellation_reason;
            state.data.cancellationDate = payload.data.cancellation_date;

            state.data.company = payload.data.company;
            state.data.emergencyContactNumber = payload.data.emergency_contact_number;
            state.data.meetingCreatedDate = payload.data.meeting_created_date;
            state.data.meetingRemarks = payload.data.meeting_remarks;
            state.data.personInCharge = payload.data.person_in_charge;

            state.data.meeting_created_date = payload.data.meeting_created_date;
            state.data.cancellation_date = payload.data.cancellation_date;
        },
        setSchedule: (state, { payload }) => {
            state.data.scheduleDatetime = payload.data.schedule_datetime;
            state.data.joinUrl = payload.data.join_url;
            state.data.password = payload.data.password;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getApplicationDetails.pending, (state: ApplicationDetailsState) => {
            state.type = GET_APPLICATION_DETAILS;
            state.loading = true;
        });
        builder.addCase(getApplicationDetails.rejected, (state: ApplicationDetailsState) => {
            state.type = GET_APPLICATION_DETAILS;
            state.loading = false;
        });
        builder.addCase(getApplicationDetails.fulfilled, (state: ApplicationDetailsState) => {
            state.type = GET_APPLICATION_DETAILS;
            state.loading = false;
        });
        builder.addCase(requestChukidanConsultation.pending, (state: ApplicationDetailsState) => {
            state.type = REQUEST_CHUKIDAN_CONSULTATION;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(requestChukidanConsultation.rejected, (state: ApplicationDetailsState) => {
            state.type = REQUEST_CHUKIDAN_CONSULTATION;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(requestChukidanConsultation.fulfilled, (state: ApplicationDetailsState) => {
            state.type = REQUEST_CHUKIDAN_CONSULTATION;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(cancelApplication.pending, (state: ApplicationDetailsState) => {
            state.type = CANCEL_APPLICATION;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(cancelApplication.rejected, (state: ApplicationDetailsState) => {
            state.type = CANCEL_APPLICATION;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(cancelApplication.fulfilled, (state: ApplicationDetailsState) => {
            state.type = CANCEL_APPLICATION;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(noChukidanConsultationRequest.pending, (state: ApplicationDetailsState) => {
            state.type = NO_CHUKIDAN_CONSULTATION_REQUEST;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(noChukidanConsultationRequest.rejected, (state: ApplicationDetailsState) => {
            state.type = NO_CHUKIDAN_CONSULTATION_REQUEST;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(noChukidanConsultationRequest.fulfilled, (state: ApplicationDetailsState) => {
            state.type = NO_CHUKIDAN_CONSULTATION_REQUEST;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(setApplicationSchedule.pending, (state: ApplicationDetailsState) => {
            state.type = SET_APPLICATION_SCHEDULE;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(setApplicationSchedule.rejected, (state: ApplicationDetailsState) => {
            state.type = SET_APPLICATION_SCHEDULE;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(setApplicationSchedule.fulfilled, (state: ApplicationDetailsState) => {
            state.type = SET_APPLICATION_SCHEDULE;
            state.loading = false;
            state.success = true;
        });
    },
});

export const { reset, setApplicationDetails, setSchedule } = applicationDetailsSlice.actions;
export const selectApplicationDetails = (state: RootState) => state.applicationDetails;
