import { Button, Form, Input, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { PolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';

type Props = {
    uuid: string;
    isVisible: boolean;
    application: PolicyApplicationDetails;
    onClose: () => void;
};

const ResultCancelMeetingModal = (props: Props) => {
    const { isVisible, application, onClose } = props;
    const [form] = Form.useForm();

    const onSubmit = async (values: { reason: string }) => {
        onClose();
    };

    return (
        <Modal className='kisoku-details-meetings-modal' width={800} visible={isVisible} footer={null} onCancel={onClose}>
            <div className='title-modal'>面談予約をキャンセルしました</div>
            <Form form={form} onFinish={onSubmit} layout='vertical'>
                <div className='cancel-info'>
                    <div className='w-25 p-3 cancel-info-left'>面談対象規程</div>
                    <div className='w-75 p-3'>{application?.type}</div>
                </div>
                <div className='cancel-info border-bottom'>
                    <div className='w-25 p-3 cancel-info-left'>面談日時</div>
                    <div className='w-75 p-3'>{application?.scheduleDatetime}</div>
                </div>
                <div className='form-buttons mt-4 justify-content-center'>
                    <Button className='general-button cancel-button' onClick={onClose}>
                        <span className='me-3'>
                            <CloseOutlined />
                        </span>
                        閉じる
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ResultCancelMeetingModal;
