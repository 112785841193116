import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FundsLocationState } from 'pages/kouteki/DiagnoseResult';

type ChildComponentProps = {
    onCheckboxChange: (newCheckedList: string[]) => void;
    checked: string[];
};

const DiagnoseAllowConsultation: React.FC<ChildComponentProps> = ({ onCheckboxChange, checked }) => {
    const onCheckboxChangeInternal = (e: CheckboxChangeEvent) => {
        const { value, checked: isChecked } = e.target;
        let newCheckedList = [...checked];

        if (isChecked) {
            newCheckedList.push(value);
        } else {
            newCheckedList = newCheckedList.filter((item) => item !== value);
        }

        onCheckboxChange(newCheckedList);
    };

    const [form] = Form.useForm();
    const { funds } = useLocation().state as FundsLocationState;
    const basicRequirementsChecked = funds.find((code) => code.title === '基本確認項目')?.result ?? true;
    const fundsWithoutBasicRequirements = funds.filter((fund) => fund.code);
    const isShowNote = fundsWithoutBasicRequirements.some((fund) => fund.result);
    const fundType = funds.some((e) => e.type === 4);
    const [isFundMultiple, setIsFundMultiple] = useState(false);

    useEffect(() => {
        let counter = 0;
        funds
            .filter((fund) => fund.title !== '基本確認項目' && fund.result === true)
            .map((fund) => {
                if (fund.allowConsultation && fund.requirements.length !== 0) {
                    counter++;
                }
            });
        setIsFundMultiple(counter > 1);
    }, [funds, fundType]);

    return (
        <>
            {/* This is where mapping of results happened. */}
            <Form form={form} layout='vertical' autoComplete='off'>
                {funds
                    .filter((fund) => fund.title !== '基本確認項目')
                    .map((fund, key) =>
                        basicRequirementsChecked && fund.result ? (
                            <Fragment key={key}>
                                {isShowNote && fund.allowConsultation ? (
                                    <div>
                                        <Row className='inner-content'>
                                            <Col xs={24} md={24}>
                                                <Row gutter={16} className='align-items-center'>
                                                    <Col md={isFundMultiple ? 16 : 17} className='grant-name'>
                                                        <a
                                                            href={`/kouteki/funds/${fund.code}/details`}
                                                            className='grant-details-page'
                                                            target='_blank'
                                                            rel='noreferrer'
                                                        >
                                                            {fund.title}
                                                        </a>
                                                    </Col>
                                                    {isFundMultiple && (
                                                        <Col md={5}>
                                                            <Row className='add-to-consult'>
                                                                <Checkbox
                                                                    className='checkbox-consult'
                                                                    value={fund.code}
                                                                    checked={checked.includes(fund.code)}
                                                                    onChange={onCheckboxChangeInternal}
                                                                >
                                                                    相談項目に追加する
                                                                </Checkbox>
                                                            </Row>
                                                        </Col>
                                                    )}
                                                    <Col md={isFundMultiple ? 3 : 7} className='external-link'>
                                                        <a href={fund.url} className='link-kanri' target='_blank' rel='noreferrer'>
                                                            参考ページ（外部リンク） <ExportOutlined />
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null}
                            </Fragment>
                        ) : null
                    )}
            </Form>
        </>
    );
};

export default DiagnoseAllowConsultation;
