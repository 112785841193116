import React, { useEffect, useState } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectFundApplication } from 'app/slice/fundApplicationSlice';
import DiagnoseResultValid from 'components/kouteki/DiagnoseResultValid';
import { selectFundsList } from 'app/slice/fundsListSlice';
import { LeftOutlined } from '@ant-design/icons';
import MultipleResultHeader from 'components/kouteki/MultipleResultHeader';
import DiagnoseResultInValid from 'components/kouteki/DiagnoseResultInvalid';
import InstructionDetail from 'components/kouteki/InstructionDetail';
import { selectAuth } from 'app/slice/authSlice';
import { checkSchedule, selectCompany } from 'app/slice/companySlice';
import ScheduleLimitModal from 'components/kouteki/ScheduleLimitModal';

export type FundsLocationState = {
    funds: {
        code: string;
        title: string;
        allowConsultation: boolean;
        type: number;
        url: string;
        result: boolean;
        requirements: { content: string }[];
    }[];
    fromSchedule?: boolean;
};

const breadcrumbs: Breadcrumb[] = [
    {
        name: 'トップページ',
        url: '/kouteki',
    },
    {
        name: '検索',
        url: '/kouteki/funds/search' + localStorage.getItem('searchPath'),
    },
    {
        //name: '活用可能性を診断',
        name: '活用可能性の診断結果',
        url: '/kouteki/funds/diagnose',
    },
];

type Props = {
    onOpenLoginModal: () => void;
};

const DiagnoseResult = (props: Props) => {
    const { onOpenLoginModal } = props;
    const { canApply } = useAppSelector(selectCompany);
    const { token } = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { funds, fromSchedule } = useLocation().state as FundsLocationState;
    const [localFromSchedule, setLocalFromSchedule] = useState(fromSchedule ?? false);
    const { params } = useAppSelector(selectFundsList);
    const { loading } = useAppSelector(selectFundApplication);
    const [isShowInstruction, setIsShowInstruction] = useState(false);
    const basicCriteriaChecked = funds.find((code) => code.title === '基本確認項目')?.result ?? true;
    const additionalCriteriaChecked = funds.find((code) => code.title !== '基本確認項目')?.result ?? true;
    const fundType = funds.some((e) => e.type === 4);
    const [isFundMultiple, setIsFundMultiple] = useState(false);

    //Check if multiple grants diagnosis
    useEffect(() => {
        let fundCount = funds.length;
        // Adjust fundCount based on fundType
        if (fundType) {
            fundCount -= 1;
        }
        // Set isFundMultiple based on fundCount
        setIsFundMultiple(fundCount > 1);
    }, [fundType]);

    const [isScheduleLimitVisible, setIsScheduleLimitVisible] = useState(false);
    const onCloseScheduleLimitModal = () => setIsScheduleLimitVisible(false);
    const onOpenScheduleLimitModal = () => setIsScheduleLimitVisible(true);

    useEffect(() => {
        if (!funds) {
            navigate('/kouteki', { replace: true });
        }
    }, [dispatch, navigate, funds]);

    // If navigated from schedule diagnose page, show instruction
    useEffect(() => {
        if (localFromSchedule && !isShowInstruction) {
            // Update the local copy of fromSchedule
            setLocalFromSchedule(false);
            setIsShowInstruction(true);
        }
    }, [localFromSchedule]);

    const returnToPrev = () => {
        const filteredParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => value));

        navigate({
            pathname: '/kouteki/funds/search',
            search: '?' + createSearchParams(filteredParams),
        });
    };

    const handleDiagnoseScheduleSubmit = () => {
        if (token) {
            if (canApply) {
                setIsShowInstruction(true);
                window.scrollTo(0, 0);
            } else {
                onOpenScheduleLimitModal();
            }
        } else {
            onOpenLoginModal();
        }
    };
    // call schedule on load page
    useEffect(() => {
        if (token) {
            dispatch(checkSchedule());
        }
    }, [dispatch, token]);

    return (
        <>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs} />
            </section>

            <section className='content diagnose-result-container'>
                {isShowInstruction ? (
                    <InstructionDetail
                        funds={funds}
                        setIsShowInstruction={setIsShowInstruction}
                        onCloseScheduleLimitModal={onCloseScheduleLimitModal}
                    />
                ) : (
                    <>
                        <div className='title'>活用可能性の診断結果</div>
                        <div>
                            {isFundMultiple ? (
                                <div>
                                    <MultipleResultHeader handleDiagnoseScheduleSubmit={handleDiagnoseScheduleSubmit} />
                                </div>
                            ) : (
                                <div>
                                    {!additionalCriteriaChecked || !basicCriteriaChecked ? (
                                        <div className='sub-header'>
                                            <h4>本診断は簡易診断です。</h4>
                                            <span>
                                                {/* For specific application requirements, etc., please use the contact information provided on the "Reference page". */}
                                                {/* Scenario 3. Invalid Public Funds */}
                                                具体的な申請要件などについては、「参考ページ（外部リンク）」先の管轄機関にお問い合わせください。
                                            </span>

                                            <DiagnoseResultInValid />
                                        </div>
                                    ) : (
                                        <div>
                                            {basicCriteriaChecked && additionalCriteriaChecked ? (
                                                <div>
                                                    <p className='sub-header'>
                                                        <h4>本診断は簡易診断です。</h4>
                                                        <span>
                                                            {/* For specific application requirements, etc., please use the "Expert Consultation Desk" or refer to the "Reference Page" Please use the contact point above. */}
                                                            {/* Scenario 1. Public funds that allow consultation */}
                                                            具体的な申請要件などについては、「専門家相談窓口」をご利用いただくか、「参考ページ（外部リンク）」先の管轄機関にお問い合わせください。
                                                        </span>
                                                    </p>
                                                </div>
                                            ) : (
                                                <div>
                                                    <p className='sub-header'>
                                                        <h4>本診断は簡易診断です。</h4>
                                                        <span>
                                                            {/* For specific application requirements, etc., please use the contact information provided on the "Reference page". */}
                                                            {/* Scenario 2. Public funds that does not allow consultation */}
                                                            具体的な申請要件などについては、「参照ページ」先のお問い合わせ窓口をご利用ください。
                                                        </span>
                                                    </p>
                                                </div>
                                            )}
                                            <DiagnoseResultValid handleDiagnoseScheduleSubmit={handleDiagnoseScheduleSubmit} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* new UI back to search grants btn */}
                        <div className='search-btn mt-5'>
                            <Button
                                className='back-to-search'
                                type='default'
                                disabled={loading}
                                onClick={returnToPrev}
                                icon={<LeftOutlined />}
                            >
                                もう一度検索する
                            </Button>
                        </div>
                    </>
                )}
            </section>

            <ScheduleLimitModal onClose={onCloseScheduleLimitModal} isVisible={isScheduleLimitVisible} />
        </>
    );
};

export default DiagnoseResult;
