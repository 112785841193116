import React from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import PrivacyPolicy from 'components/landing/PrivacyPolicy';

/**
 * Props for Breadcrumb
 */
const breadcrumbs: Breadcrumb[] = [
    {
        name: 'トップページ',
        url: '/kouteki',
    },
    {
        name: '個人情報取扱規約',
        url: '/kouteki/privacy-policy',
    },
];

const TermsOfUse = () => {
    return (
        <>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs}/>
            </section>
            <section className='content'>
                <PrivacyPolicy/>
            </section>
        </>
    );
};

export default TermsOfUse;
